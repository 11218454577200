import {toggleLoader} from "./common";
export const addFilled = (filled) => {
    // console.log(link);
    return {
        type: 'ADD_FILLED',
        filled
    }

};
export const sortFilled = (by,desc) => {
    return {
        type: 'SORT_FILLED',
        by,desc
    }

};
export const startGetFilled = (search,user) => {
    if(user.isAuthenticated){
        return (dispatch) => {
            // console.log(search);
            const url = process.env.BASE_URL + "get-filled-list";
            dispatch(toggleLoader());
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "access_token": user.access_token || "null"
                },
                body: JSON.stringify(search)
    
            }).then(response => response.json()
                .then(json => {
                    if(json.body){
                        dispatch(addFilled(json.body));
                        dispatch(sortFilled("timeStamp",true));
                    }
                    dispatch(toggleLoader());
                }))
        }

    }
    return false
}
export const downloadFile = (folder, item, token) => {
    const url = process.env.BASE_URL + "get-file/" + folder + "/" + item;
    fetch(url, {
        method: "GET",
        headers: { "access_token": token }
    }).then((response) => {
        const reader = response.body.getReader();
        const stream = new ReadableStream({
            start(controller) {
                // The following function handles each data chunk
                function push() {
                    // "done" is a Boolean and value a "Uint8Array"
                    reader.read().then(({ done, value }) => {
                        // Is there no more data to read?
                        if (done) {
                            // Tell the browser that we have finished sending data
                            controller.close();
                            return;
                        }

                        // Get the data and send it to the browser via the controller
                        controller.enqueue(value);
                        push();
                    });
                };

                push();
            }
        });

        return new Response(stream);
    })
        .then(response => response.blob())
        .then(blob => URL.createObjectURL(blob))
        .then(url => {
            const link = document.createElement("a");
            link.href = url;
            link.download = `${item}`
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
}
