import React from "react";
import {
    HashRouter as Router,
    Route,
    Link,
    Redirect,
    withRouter
} from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { startLogin, login ,doLogout} from "../actions/common";
import { logout} from "../actions";
import Paper from "@material-ui/core/Paper";
import { withStyles, Typography, Fab } from "@material-ui/core";
import PropTypes from 'prop-types';
import Button from "@material-ui/core/Button";
import TextField from '@material-ui/core/TextField';
import { withSnackbar } from 'notistack';


const styles = theme => ({
    root: {
        ...theme.mixins.gutters(),
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2),
        textAlign: "center",
        maxWidth: 400,
        display: "flex",
        flexDirection: "column"
    },
    div: {
        display: "flex",
        alignItems: "center",
        flexDirection:"column"
    },
    button: {
        margin: theme.spacing(1),
        fontFamily: "Boing-SemiBold",
        textTransform: "none",
    },
    header: {
        fontFamily: "Boing-Bold",
        padding: 20,
        textAlign: "center",
    }
});
class Login extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            redirectToReferrer: false,
            email: "",
            password: "",
        }
    }
    handleChange = name => event => {
        this.setState({
            [name]: event.target.value,
        });
    };
    login = () => {
        let creds = { email: this.state.email, password: this.state.password };
        this.props.startLogin(creds).then((res) => {
            if (res !== "error") {
                this.setState({ redirectToReferrer: true });
            }else{
                this.props.enqueueSnackbar("Käyttäjätunnus tai salasana väärin", { variant: "error" });
            }
        });

    }
    logout =(history)=>()=>{

        // Delete integration cookie
        const domain = '.' + window.location.hostname;
        document.cookie = `access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}`;
        document.cookie = `user=; expires=Thu, 01 Jan 1970 00:00:00 UTC; domain=${domain}`;
          
        this.props.login({ isAuthenticated: false });
        this.props.doLogout();
        history.push("/");
        this.props.enqueueSnackbar("Kirjattu ulos",{variant:"success"})
    }
    componentDidMount = () =>{
        if(!this.props.common.user.isAuthenticated && this.state.redirectToReferrer === false){
            this.props.enqueueSnackbar("Kirjaudu sisään",{variant:"info"});
        }
        
    }
    render() {
        const { classes } = this.props; 
        const AuthButton = withRouter(({ history }) => (
            this.props.common.user.isAuthenticated ? (
                <div className={classes.div}>
                    <Paper className={classes.root}>
                        <Typography variant="h5">Käyttäjä</Typography>
                        <Typography>{this.props.common.user.me.fullname}</Typography>
                        <Typography>{this.props.common.user.me.email}</Typography>
                        <Fab className={classes.button} variant="extended" color="secondary" onClick={this.logout(history)}
                            
                        >Kirjaudu ulos</Fab>
                    </Paper>
                </div>
            ) : (
                    <p>You are not logged in.</p>
                )
        ))
        const { from } = this.props.location.state || { from: { pathname: '/' } }
        const { redirectToReferrer } = this.state

        if (redirectToReferrer === true) {
            return <Redirect to={from} />
        }
        if (this.props.common.user.isAuthenticated) {
            return <AuthButton />
        } else {
            
            return (
                <div className={classes.div}>
                <Typography className={classes.header} color="textPrimary" variant="h3">RamiForms</Typography>
                    <Paper className={classes.root}>
                        <TextField
                            id="standard-email-input"
                            label="Sähköposti"
                            value={this.state.email}
                            onChange={this.handleChange("email")}
                            className={classes.textField}
                            type="email"
                            autoComplete="current-email"
                            margin="normal"
                        />
                        <TextField
                            id="standard-password-input"
                            label="Salasana"
                            value={this.state.password}
                            onChange={this.handleChange("password")}
                            className={classes.textField}
                            type="password"
                            autoComplete="current-password"
                            margin="normal"
                        />
                        <Fab className={classes.button} variant="extended" color="secondary" onClick={this.login}>Kirjaudu</Fab>
                        <Typography component="a" href={process.env.RECOVERY} target="_blank" rel="noreferrer">Unohdin salasanani</Typography>
                    </Paper>
                </div>
            )
        }
    }
}

function mapStateToPorps(state) {
    return {
        common: state.common
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startLogin: startLogin,
        login: login,
        doLogout:doLogout
    }, dispatch)
}
Login.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default connect(mapStateToPorps, mapDispatchToProps)(withStyles(styles)(withSnackbar(Login)));
