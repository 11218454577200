import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

class FormInput extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: "",
            latestAutoFill: "",
        }
    }
    handleChange = (event) => {
        if (typeof this.props.handleChange === 'function') {
            this.props.handleChange(event);
        }
        this.setState({ value: event.target.value })
    }
    static getDerivedStateFromProps(props, state) {
        if (props.autoFill !== state.latestAutoFill) {
            return { latestAutoFill: props.autoFill, value: props.autoFill }
        }
        return null
    }
    componentDidMount() {
        const { value } = this.props.model
        if ((typeof value === "string") && value !== "") {
            this.props.setDefaultValue(value)
            this.setState({ value: value })
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.props.model.value !== nextProps.model.value) {
            if (nextProps.model.value && nextProps.model.value !== "") {
                this.props.setDefaultValue(nextProps.model.value)
                this.setState({ value: nextProps.model.value })
            }
        }
    }
    render() {

        const { type, label, key, multiline, properties, required, value } = this.props.model;
        return (
            <TextField
                id={key}
                label={label}
                key={"i" + key}
                type={type}
                multiline={multiline}
                margin="normal"
                inputProps={properties}
                onChange={this.handleChange}
                variant={multiline ? "outlined" : "standard"}
                required={required || false}
                fullWidth
                defaultValue={value}
                onKeyPress={(e) => {
                    if (e.key === 'Enter' && !multiline) {
                        e.preventDefault();
                    }
                }}
            />
        )
    }
}
export default FormInput;