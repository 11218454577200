/* eslint-disable react/prop-types, react/jsx-handler-names */

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';
import { FormControl } from '@material-ui/core';
import _ from "lodash";

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: 5,
    },
    input: {
        display: 'flex',
        padding: 0,
        height: 'unset',
        color: '#003185'
    },
    valueContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        flex: 1,
        alignItems: 'center',
        overflow: 'hidden',
    },
    chip: {
        margin: `${theme.spacing(0.5)}px ${theme.spacing(0.25)}px`,
    },
    chipFocused: {
        backgroundColor: emphasize(
            theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
            0.08,
        ),
    },
    noOptionsMessage: {
        padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
    },
    singleValue: {
        fontSize: 16,
    },
    placeholder: {
        position: 'absolute',
        left: 2,
        fontSize: 16,
    },
    paper: {
        position: 'absolute',
        zIndex: 10,
        marginTop: theme.spacing(1),
        left: 0,
        right: 0,
    },
    divider: {
        height: theme.spacing(2),
    },
});

function NoOptionsMessage(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.noOptionsMessage}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function inputComponent({ inputRef, ...props }) {
    return <div ref={inputRef} {...props} />;
}

function Control(props) {
    return (
        <TextField
            fullWidth
            InputProps={{
                inputComponent,
                inputProps: {
                    className: props.selectProps.classes.input,
                    inputRef: props.innerRef,
                    children: props.children,
                    ...props.innerProps,
                },
            }}
            {...props.selectProps.textFieldProps}
        />
    );
}

function Option(props) {
    return (
        <MenuItem
            buttonRef={props.innerRef}
            selected={props.isFocused}
            component="div"
            style={{
                fontWeight: props.isSelected ? 500 : 400,
            }}
            {...props.innerProps}
        >
            {props.children}
        </MenuItem>
    );
}

function Placeholder(props) {
    return (
        <Typography
            color="textSecondary"
            className={props.selectProps.classes.placeholder}
            {...props.innerProps}
        >
            {props.children}
        </Typography>
    );
}

function SingleValue(props) {
    return (
        <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
            {props.children}
        </Typography>
    );
}

function ValueContainer(props) {
    return <div className={props.selectProps.classes.valueContainer}>{props.children}</div>;
}

function MultiValue(props) {
    return (
        <Chip
            tabIndex={-1}
            label={props.children}
            className={classNames(props.selectProps.classes.chip, {
                [props.selectProps.classes.chipFocused]: props.isFocused,
            })}
            onDelete={props.removeProps.onClick}
            deleteIcon={<CancelIcon {...props.removeProps} />}
        />
    );
}

const components = {
    Control,
    MultiValue,
    NoOptionsMessage,
    Option,
    Placeholder,
    SingleValue,
    ValueContainer
};

const getDefaultValueByName = (options, defaulValueName) => options.find(option => option.label === defaulValueName) 
class Autocomplete extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            [this.props.name]: this.props.isMulti ? { params: null, value: null } : null
        };
    }

    handleChange = name => value => {
        this.setState({
            [name]: value,
        });
    };

    componentDidMount() {
        const defaultValue = getDefaultValueByName(this.props.options, this.props.defaultValueName)
        const setAutoCompleteValue = this.props.onAutocompleteChange(this.props.name);
        if(defaultValue) {
            setAutoCompleteValue(defaultValue)
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!_.isEqual(this.props.options, nextProps.options)) {
            const defaultValue = getDefaultValueByName(nextProps.options, nextProps.defaultValueName);
            const setAutoCompleteValue = this.props.onAutocompleteChange(this.props.name);
            if (defaultValue) {
                setAutoCompleteValue(defaultValue);
            }
        }
    }
    
    render() {
        const { classes, theme } = this.props;
        const selectStyles = {
            input: base => ({
                ...base,
                color: theme.palette.text.primary,
                '& input': {
                    font: 'inherit',
                },
            }),
        };
        return (
            <div className={classes.root}>
                <NoSsr>
                    <FormControl required={this.props.required} key={this.props.name} fullWidth onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}>
                        <Typography htmlFor={this.props.name + "-required"} lang="en" className={classes.label} variant="subtitle1" color="textSecondary">{this.props.settings.label}{this.props.required ? "*" : ""}</Typography>
                        {this.props.isMulti ?
                            <Select
                                classes={classes}
                                styles={selectStyles}
                                textFieldProps={{
                                    label: this.props.settings.label,
                                    InputLabelProps: {
                                        shrink: true,
                                    },
                                }}
                                options={this.props.options}
                                menuPlacement="auto"
                                components={components}
                                value={this.props.value}
                                onChange={this.props.onAutocompleteChange(this.props.name)}
                                placeholder={this.props.settings.placeholder}
                                isMulti={this.props.isMulti}
                                
                            /> : <Select
                                classes={classes}
                                styles={selectStyles}
                                menuPlacement="auto"
                                options={this.props.options}
                                components={components}
                                value={this.props.value}
                                onChange={this.props.onAutocompleteChange(this.props.name)}
                                placeholder={this.props.settings.placeholder}
                                isClearable
                                filterOption={(candidate, input) => {
                                    return candidate.label.toLowerCase().includes(input.toLowerCase());
                                }}
                            />}

                    </FormControl>
                </NoSsr>
            </div>
        );
    }
}

Autocomplete.propTypes = {
    classes: PropTypes.object.isRequired,
    theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Autocomplete);