export default (state = [], action) => {
    
    switch (action.type) {
        case 'ADD_DATA':
            let existing = false;
            state.map((f) => {
                if (f.formId === action.data.form.formId) {
                    existing = true;
                }
            })
            if (existing) {
                return state.map(
                    data =>
                        (data.formId === action.data.form.formId)
                            ? { ...action.data, visible: true, formId: action.data.form.formId }
                            : data
                )
            } else {
                return [
                    ...state,
                    {
                        ...action.data,
                        formId: action.data.form.formId,
                        visible: true
                    }
                ];
            }
        case 'TOGGLE_DATA':
            return state.map(
                data =>
                    (data.formId === action.formId)
                        ? { ...data, visible: !data.visible }
                        : data
            )
        default:
            return state;
    }
};