import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Checkbox from "@material-ui/core/Checkbox";
import InputRow from "./InputRow";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    icons: {
        display: "flex",
    },
    cell: {
        padding: "0px 10px"
    }
});


const StaticCell = ({ rowIndex, cellIndex, cell, fields }) => {
    try {
        if (fields[cellIndex].type === "checkbox") {
            return (
                <TableCell key={`cell-${rowIndex}-${cellIndex}`}>
                    <Checkbox
                        checked={cell || false}
                    />
                </TableCell>
            )
        }
        return (
            <TableCell key={`cell-${rowIndex}-${cellIndex}`}>{cell}</TableCell>
        )
    } catch (error) {
        // console.log(fields, cellIndex)
        return <div></div>
    }

}
class FormTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rows: [],
            edit: false,
            screenWidth:0,
        };


    }
    componentDidMount=()=> {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount=()=> {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions=()=> {
        this.setState({ screenWidth: window.innerWidth });
    }
    handleAddRow = (event, data) => {
        let rows = this.state.rows;
        let row = [];
        this.props.fields.map((f, i) => {
            row.push(data[i]);
        })
        rows.push(row);
        this.setState({ rows: rows })
        this.props.handleTableChange(rows);
    }
    handleDeleteRow = (rowIndex) => {
        let rows = this.state.rows;
        rows.splice(rowIndex, 1);
        this.setState({ rows: rows })
        this.props.handleTableChange(rows);
    }
    handleRowCopy = (rowIndex) => {
        let rows = this.state.rows;
        let newRow = rows[rowIndex];
        rows.splice(rowIndex, 0, newRow);
        this.setState({ rows: rows })
        this.props.handleTableChange(rows);
    }
    handleEditRow = (rowIndex) => {
        this.setState({ edit: rowIndex })
    }
    handleSaveRow = (data) => {
        if (data === "cancel") {
            this.setState({ edit: false })
        } else {
            let rows = this.state.rows;
            let row = [];
            this.props.fields.map((f, i) => {
                row.push(data[i]);
            })
            rows.splice(this.state.edit, 1, row);
            this.setState({ rows: rows, edit: false })
            this.props.handleTableChange(rows);
        }

    }


    render() {
        const { classes, fields } = this.props;
        return (
            <Paper className={classes.root}>
                <Table className={classes.table} >
                    <TableHead>
                        <TableRow>
                            <TableCell key="empty" className={classes.cell}></TableCell>
                            {fields.map(f => {
                                return (<TableCell key={f.label}>{f.label}</TableCell>)
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.rows.map((row, rowIndex) => {
                            if (rowIndex === this.state.edit) {

                                return (
                                    <InputRow
                                        edit
                                        values={row}
                                        fields={fields}
                                        classes={classes}
                                        saveRow={this.handleSaveRow}
                                    />
                                )
                            }
                            return (<TableRow key={`row-${rowIndex}`}>
                                <TableCell component="td" scope="row" key={`buttons-${rowIndex}`}>
                                    <div className={classes.icons}>
                                        <IconButton
                                            key={`edit-${rowIndex}`}
                                            onClick={() => this.handleEditRow(rowIndex)}
                                            variant="outlined"
                                            className={classes.button}
                                        >
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton
                                            key={`copy-${rowIndex}`}
                                            onClick={() => this.handleRowCopy(rowIndex)}
                                            variant="outlined"
                                            className={classes.button}
                                        >
                                            <CopyIcon />
                                        </IconButton>
                                        <IconButton
                                            key={`delete-${rowIndex}`}
                                            onClick={() => this.handleDeleteRow(rowIndex)}
                                            variant="outlined"
                                            className={classes.button}
                                        >
                                            <DeleteIcon></DeleteIcon>
                                        </IconButton>
                                    </div>
                                </TableCell>
                                {row.map((cell, cellIndex) => {
                                    return (
                                        <StaticCell
                                            key={`static-${cellIndex}`}
                                            cell={cell}
                                            cellIndex={cellIndex}
                                            rowIndex={rowIndex}
                                            fields={fields}
                                        />
                                    )
                                })}

                            </TableRow>)
                        })}
                        <InputRow
                            fields={fields}
                            classes={classes}
                            handleAddRow={this.handleAddRow}
                        />
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

export default withStyles(styles)(FormTable);
