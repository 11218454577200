import React from "react";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import AddIcon from "@material-ui/icons/Add";
import DoneIcon from "@material-ui/icons/Done";
import ClearIcon from "@material-ui/icons/Clear"
import Checkbox from "@material-ui/core/Checkbox";
import Dropdown from "./Dropdown";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    icons: {
        display: "flex",
    },
    cell: {
        padding: "0px 10px"
    }
});

class InputRow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            newRow: {},
            initRow: {},
        };
        if (this.props.edit) {
            // console.log(this.props.values)
            this.props.fields.map((f, i) => {
                this.state.newRow[i] = this.props.values[i];
            })
        } else {
            this.props.fields.map((f, i) => {
                this.state.newRow[i] = "";
            })
        }
        this.state.initRow = this.state.newRow;

    }
    handleNewRowChange = name => event => {
        let target = "";
        event.target.type === "checkbox" ? target = "checked" : target = "value"
        this.setState({
            newRow: {
                ...this.state.newRow,
                [name]: event.target[target]
            }
        })
    }
    addRow = (event) => {
        if (event.key === "Enter" || event.type === "click") {
            event.preventDefault();
            event.stopPropagation();
            this.props.handleAddRow(event, this.state.newRow)
            this.setState({ newRow: this.state.initRow })
        }

    }
    saveRow = () => {
        this.props.saveRow(this.state.newRow)
    }
    cancelChanges = () => {
        this.props.saveRow("cancel")
    }
    render() {
        const { classes, fields } = this.props
        return (
            <TableRow key="newRow" onKeyDown={this.addRow}>
                <TableCell component="th" scope="row" className={classes.cell}>
                    {this.props.edit ?
                        <div>
                            <IconButton
                                onClick={this.saveRow}
                                variant="outlined"
                                className={classes.button}
                            >
                                <DoneIcon />
                            </IconButton>
                            <IconButton
                                onClick={this.cancelChanges}
                                variant="outlined"
                                className={classes.button}
                            >
                                <ClearIcon />
                            </IconButton>
                        </div>
                        :
                        <IconButton
                            onClick={this.addRow}
                            variant="outlined"
                            className={classes.button}
                        >
                            <AddIcon />
                        </IconButton>

                    }
                </TableCell>
                {fields.map((f, index) => {
                    switch (f.type) {
                        case "checkbox":
                            return (
                                <TableCell key={`${f.label}${index}`} className={classes.cell}>
                                    <Checkbox
                                        checked={this.state.newRow[index]}
                                        onChange={this.handleNewRowChange(index)}
                                        value={f.label}
                                    />
                                </TableCell>)
                        case "dropdown":
                            return (
                                <TableCell key={`${f.label}${index}`} className={classes.cell}>
                                    <Dropdown
                                        name={f.label}
                                        // label=""
                                        value={this.state.newRow[index]}
                                        options={f.props}
                                        onDropdownChange={this.handleNewRowChange(index)}
                                    />
                                </TableCell>


                            )
                        case "decimalNumber":
                            return (
                                <TableCell key={`${f.label}${index}`} className={classes.cell}>
                                    <TextField
                                        type="number"
                                        onChange={this.handleNewRowChange(index)}
                                        id={f.label}
                                        inputProps={{ step: 0.01 }}
                                        value={this.state.newRow[index]}
                                        margin="normal"
                                    />
                                </TableCell>)
                        default:
                            return (
                                <TableCell key={`${f.label}${index}`} className={classes.cell}>
                                    <TextField
                                        type={f.type}
                                        onChange={this.handleNewRowChange(index)}
                                        id={f.label}
                                        value={this.state.newRow[index]}
                                        margin="normal"
                                    />
                                </TableCell>)
                    }
                })}

            </TableRow>
        )
    }
}
export default withStyles(styles)(InputRow);