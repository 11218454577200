import React from "react";
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { addData, startSetForms, toggleData } from "../actions/data";
import { getAutomationOptions, logout } from "../actions/common";
import BuildForm from "./BuildForm";
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import "../fonts/fonts.scss"
import Login from "./Login";
import { Prompt } from "react-router-dom";
import BackIcon from "@material-ui/icons/ArrowBack";

import {
    HashRouter as Router,
    Route,
    Link,
    Redirect,
    withRouter
} from "react-router-dom";
import { IconButton } from "@material-ui/core";
const styles = theme => ({
    root: {
        margin: 10,
        flexGrow: 1,
    },
});

const isLocalhost = () => {
    return (window.location.href).includes('localhost');
}

const urlsPreventingReload = ['outokumpu', 'metsafibre'];

const hasAnyOfPreventingUrlSubstrings = (url) => urlsPreventingReload.some((urlPart) => url.includes(urlPart));

const disableUnload = (event) => {
    if (!isLocalhost() || !hasAnyOfPreventingUrlSubstrings(this.props.history.location.pathname)) {
        event.preventDefault();
        event.returnValue = '';
    }
}

class Form extends React.Component {
    constructor(props) {
        super(props)
        this.state = {reload:false}

    }
    componentDidMount() {
        const getOptions = (res) => {
            res.form.map(f => {
                if (f.type === "automation-options") {
                    this.props.getAutomationOptions(f.value, this.props.common.user.access_token)
                }
            })
        }
        let location = { from: this.props.location };
        this.props.startSetForms(this.props.formId, this.props.common.user.access_token)
            .then(getOptions)
            .catch((err) => {
                if (err && err.status === 'error.invalidtoken') {
                    this.props.logout();
                    this.props.history.push({ pathname: '/login', state: location })
                }
            });
    }

    handleClick = () => {
        this.props.history.goBack();
    }
    reloadForm =()=>{
        // console.log("RELOAD")
        window.onbeforeunload = () => { };
        location.reload();
    }

    shouldPromptReload = () => {
        const skipPrompt = ['outokumpu', 'metsafibre'];
        return !skipPrompt.some((skippable) => this.props.history.location.pathname.includes(skippable)) &&
        this.props.common.hasEdits;
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Prompt message="Sinulla on tallentamattomia muutoksia. Oletko varma, että haluat poistua lomakkeesta?" when={this.shouldPromptReload()}/>
                {this.props.data.map(d => {
                    if (d.formId === this.props.formId) {
                        return (
                            <div key ={d.form.formId+"div"}>
                                <IconButton key ="backbutton" onClick={this.handleClick}>
                                    <BackIcon></BackIcon>
                                </IconButton>
                                <BuildForm key={d.form.title} model={d.form} user={this.props.common.user} options={this.props.common.settings} reloadForm = {this.reloadForm}/>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }
}
function mapStateToPorps(state) {
    return {
        data: state.data,
        common: state.common
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addData: addData,
        startSetForms: startSetForms,
        toggleData: toggleData,
        getAutomationOptions: getAutomationOptions,
        logout
    }, dispatch)
}
Form.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToPorps, mapDispatchToProps)(withStyles(styles)(withRouter(Form)));