export default (state = [], action) => {
    switch (action.type) {
        case 'ADD_FILLEDFORMS':
            return [
                ...state,
                {
                    ...action.data
                }
            ];
        default:
            return state;
    }
};