import { startSetLinks } from "./link";
import jwt_decode from "jwt-decode";

export const toggleLoader = () => {
    return {
        type: 'TOGGLE_LOADER'
    }
};
export const toggleSpinner = () => {
    return {
        type: 'TOGGLE_SPINNER'
    }
};

export const setSpinner = (showSpinner) => {
    return {
        type: 'SET_SPINNER',
        data: showSpinner
    }
}

export const login = (user) => {
    // console.log(link);
    return {
        type: 'LOGIN',
        user
    }

};
export const logout = () => {
    // console.log(link);

    return {
        type: 'LOGOUT',

    }

};
export const setSettings = (data) => {
    return {
        type: "SET_SETTINGS",
        data
    }
}
export const setFavorites = (data) => {
    return {
        type: "SET_FAVORITES",
        data
    }
}
export const setFileLoading = (percentage) => {
    return {
        type: "SET_FILELOADER",
        percentage
    }
}
export const setSearchFilled = (data) => {
    return {
        type: "SET_SEARCHFILLED",
        data
    }
}
const url = process.env.AUTH_URL;

const parseValidResponse = (response, dispatch) => {
    if (response.status !== 200) {
        dispatch(doLogout());
    } else {
        return response.json();
    }
};

function doLogin(creds) {
    return dispatch => {
        return fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(creds),

        }).then(response => response.json()
            .then(json => {
                console.log("response");
                if (json.access_token) {
                    // Set access token to cookie for rf3 auth integration
                    const decodedToken = jwt_decode(json.access_token)
                    document.cookie = `access_token=${json.access_token}; domain=.${window.location.hostname}; expires=${new Date(decodedToken.exp*1000).toUTCString()}`
                    const user = {
                        firstName: (json && json.me && json.me.first_name) || '', 
                        lastName: (json && json.me && json.me.last_name) || '', 
                        phoneNumber: (json && json.me && json.me.phone_number) || '', 
                        email: (json && json.me && json.me.email) || ''
                    }
                    document.cookie = `user=${btoa(JSON.stringify(user))}; domain=.${window.location.hostname}; expires=${new Date(decodedToken.exp*1000).toUTCString()}`
                    
                    for (let role in json.me.roles.all) {
                        if (json.me.roles.all[role].role_url === "RamiForms\\RamiForms user") {
                            // alert("Welcome "+json.me.fullname);
                            dispatch(login({ ...json, isAuthenticated: true }))
                            dispatch(startSetLinks(json.access_token, json.me.email));
                        }
                    }
                    // alert("No permission");

                } else {
                    return "error";
                }

            }))
    }
}
export const getAutomationOptions = (id, token) => {
    return dispatch => {
        return fetch(process.env.BASE_URL + "get-settings", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "access_token": token
            },
            body: JSON.stringify({ type: "options", id: id }),

        })
        .then((response) => parseValidResponse(response, dispatch))
        .then(json => {
            if (json && json.body) {
                dispatch(setSettings(json.body));
            }
        })
    }
}
export const getFavorites = (id, token) => {
    return dispatch => {
        return fetch(process.env.BASE_URL + "get-settings", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "access_token": token
            },
            body: JSON.stringify({ type: "user", id: id }),
        }).then((response) => parseValidResponse(response, dispatch)).then(json => {
            if (json && json.body && json.body.length > 0) {
                dispatch(setFavorites(json.body[0]))
            }
        })
    }
}
export const editFavorites = (data, token, id) => {
    return dispatch => {
        // dispatch(loaderON());
        data.id = id
        return fetch(process.env.BASE_URL + "post-settings", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "access_token": token
            },
            body: JSON.stringify(data),

        }).then(response => response.json()
            .then(json => {
                if (json.body.message === "success") {
                    dispatch(getFavorites(id, token));
                } else {
                    // dispatch(loaderOFF());
                }
                // dispatch(setSettings([json.body]))
            }))
    }
}
export const doLogout = () => {
    return dispatch => {

        dispatch(logout())
        dispatch(startSetLinks());


    }
}
export function startLogin(creds) {
    return (dispatch) => {
        return dispatch(doLogin(creds))
    }


}

const preventReload = (e) => {
  e.preventDefault();
  // Chrome requires returnValue to be set
  e.returnValue = '';
}

export const setHasEdits = (hasUnsavedEdits) => {
    return dispatch => {
        if (hasUnsavedEdits) {
            window.addEventListener('beforeunload', preventReload);
        } else {
            window.removeEventListener('beforeunload', preventReload);
        }
        return dispatch({type: 'SET_HAS_EDITS', hasEdits: hasUnsavedEdits});
    }
}