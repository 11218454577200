import { createStore,applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import rootReducer from '../reducers'; // the value from combineReducers
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
 key: 'root',
 storage: storage,
 stateReconciler: autoMergeLevel2 ,// see "Merge Process" section for details.
 blacklist: ['data']
};

const pReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(pReducer,composeEnhancers(applyMiddleware(thunk)));
export const persistor = persistStore(store);