export default (state = {tree:{},forms:[]}, action) => {
    switch (action.type) {
        case 'ADD_LINK':
            return [
                ...state,
                { forms: [...state.forms,action.link] }
            ];
        case 'ADD_LINKS':
            return {...state,forms:action.links};
        case 'ADD_TREE':
            return {...state,tree: action.tree};
        default:
            return state;
    }
};