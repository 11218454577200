import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Checkbox from "@material-ui/core/Checkbox";


const styles = theme => ({
    
    icons: {
        display: "flex",
    },
});
const StaticCell = ({ rowIndex, cellIndex, cell, fields }) => {
    try {
        if (fields[cellIndex].type === "checkbox") {
            return (
                <TableCell key={`cell-${rowIndex}-${cellIndex}`}>
                    <Checkbox
                        checked={cell || false}
                    />
                </TableCell>
            )
        }
        return (
            <TableCell key={`cell-${rowIndex}-${cellIndex}`}>{cell}</TableCell>
        )
    } catch (error) {
        // console.log(fields, cellIndex)
        return <div></div>
    }

}
class MobileTableRow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
            src: ""
        }
    }
    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };
    render() {
        const { classes,index } = this.props;
        return (
            <TableRow key={`row-${index}`}>
                <TableCell component="td" scope="row" key={`buttons-${index}`}>
                    <div className={classes.icons}>
                        <IconButton
                            key={`edit-${index}`}
                            onClick={() => this.props.handleEdit(this.props.index)}
                            variant="outlined"
                            className={classes.button}
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            key={`copy-${index}`}
                            onClick={() => this.props.handleCopy(this.props.index)}
                            variant="outlined"
                            className={classes.button}
                        >
                            <CopyIcon />
                        </IconButton>
                        <IconButton
                            key={`delete-${index}`}
                            onClick={() => this.props.handleDelete(this.props.index)}
                            variant="outlined"
                            className={classes.button}
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    </div>
                </TableCell>
                {this.props.value.map((cell, cellIndex) => {
                    return (
                        <StaticCell
                            key={`static-${cellIndex}`}
                            cell={cell}
                            cellIndex={cellIndex}
                            rowIndex={index}
                            fields={this.props.fields}
                        />
                    )
                })}

            </TableRow>)

    }
}

MobileTableRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(MobileTableRow));