import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { toggleLoader, toggleSpinner, setSearchFilled } from "../actions/common";
import { startGetFilled } from "../actions/filled";
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Button from "@material-ui/core/Button";
import Box from '@material-ui/core/Box';
const styles = theme => ({
    root: {
        display: "flex",
        flexWrap: "wrap",
        padding: 10
    },
    grid: {
        width: "100%",
        margin: 0
    },
    break: {
        flexBasis: "100%"
    },
    header: {
        fontFamily: "Boing-Bold",
        color: theme.palette.text.primary
    }
});
const yesterday = new Date(new Date().getTime() - 86400000);
const date = yesterday.toISOString().split('T')
class SearchFilled extends React.Component {
    constructor(props) {
        super(props)
        // this.state = {
        //     updated: "ei koskaan",
        //     searchWord:"",
        //     title: "",
        //     startDate: date[0],
        //     endDate: ""
        // }
    }
    componentDidMount = () => {
        // const search = [
        //     ...this.props.common.searchFilled,
        //     {
        //         filledBy: this.props.common.user.me.email
        //     }
        // ];
        // this.props.startGetFilled(search, this.props.common.user)
        // this.setState({ updated: new Date().toLocaleString() })
        this.onSubmit();
    }
    onSubmit = (e) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }
        const search = [
            {
                filledBy: this.props.common.user.me.email
            }
        ];
        if (this.props.common.searchFilled.searchWord) {
            search.push({ searchWord: this.props.common.searchFilled.searchWord });
        }
        if (this.props.common.searchFilled.formId) {
            search.push({ formId: this.props.common.searchFilled.formId });
        }
        if (this.props.common.searchFilled.title) {
            search.push({ title: this.props.common.searchFilled.title });
        }
        if (this.props.common.searchFilled.productId) {
            search.push({ productId: this.props.common.searchFilled.productId })
        }
        let date = {};
        if (this.props.common.searchFilled.startDate !== "") {
            date["startDate"] = new Date(this.props.common.searchFilled.startDate).getTime();
        }
        if (this.props.common.searchFilled.endDate !== "") {
            date["endDate"] = new Date(this.props.common.searchFilled.endDate).getTime() + 86400000;
        }
        if (date.startDate || date.endDate) {
            search.push({ date: date });
        }
        this.props.startGetFilled(search, this.props.common.user)
        // this.setState({ updated: new Date().toLocaleString() })
    }
    handleChange = name => (event) => {
        this.props.setSearchFilled({ ...this.props.common.searchFilled, [name]: event.target.value });
    }
    render() {
        const { classes } = this.props;
        return (
            <>
                <h2 className={classes.header}>Täyttämäni lomakkeet</h2>
                <Typography>Huom.! Täyttämäsi tilaustietolomakkeet eivät näy tässä näkymässä, löydät ne <a href={process.env.RF3_URL + "tilaukset"}>täältä</a></Typography>
                <form className={classes.root} onSubmit={this.onSubmit}>
                    <TextField
                        id="outlined-name"
                        label="Alkaen"
                        className={classes.textField}
                        value={this.props.common.searchFilled.startDate}
                        type="date"
                        onChange={this.handleChange('startDate')}
                        variant="outlined"
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        id="outlined-name"
                        label="Päättyen"
                        className={classes.textField}
                        value={this.props.common.searchFilled.endDate}
                        type="date"
                        onChange={this.handleChange('endDate')}
                        variant="outlined"
                        margin="dense"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <div className={classes.break} />
                    <Button variant="contained" color="secondary" type="submit">Hae</Button>
                </form>
            </>
        );
    }
}
function mapStateToPorps(state) {
    return {
        common: state.common,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleLoader: toggleLoader,
        toggleSpinner: toggleSpinner,
        startGetFilled: startGetFilled,
        setSearchFilled: setSearchFilled
    }, dispatch)
}
SearchFilled.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToPorps, mapDispatchToProps)(withStyles(styles)(SearchFilled));