import React from "react";
import RamiIdea from "../images/Ramirent_Rami_Idea.png";
import { makeStyles, ButtonBase, Typography } from "@material-ui/core";
import theme from "./theme";

const useStyles = makeStyles((theme) => {
  return {
    base: {
      width: "100%",
    },
    line: {
      width: 65,
      height: 3,
      backgroundColor: "rgb(255, 220, 0)",
      marginTop: 12,
      position: "absolute",
      bottom: 18,
      right: 26,
    },
    header: {
      textAlign: "center",
      fontFamily: "Boing-Bold",
      color: theme.palette.text.primary
    },
    content: {
      background:
        "linear-gradient(rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.8))",
      height: "100%",
      width: "100%",
      borderRadius: 10,
    },
    imageSrc: {
      height: 100,
      padding: 20,
    },
    imageTitle: {
      fontFamily: "Boing-Regular",
      padding: 15,
      position: "absolute",
      left: 10,
      bottom: 10,
    },
  };
});

const image = {
  url: RamiIdea,
  title: "Uusi Tilaustietolomake",
  color: theme.palette.text.primary,
  textColor: theme.palette.text.white
};

const Rf3Link = () => {
  const classes = useStyles();

  return (
    <ButtonBase
      className={classes.image}
      focusRipple
      key={image.title}
      focusVisibleClassName={classes.focusVisible}
      component={"a"}
      href={process.env.RF3_URL}
      style={{
        backgroundImage: `url(${image.url})`,
        backgroundRepeat: "no-repeat",
        width: window.innerWidth < 360 ? "90%" : 360,
        height: 200,
        margin: 10,
        borderRadius: 10,
      }}
    >
      <div className={classes.content}>
        <span className={classes.imageButton}>
          <Typography
            align={"right"}
            component="span"
            variant="h4"
            style={{ color: image.textColor }}
            className={classes.imageTitle}
          >
            {image.title}
          </Typography>
          <div className={classes.line} />
        </span>
      </div>
    </ButtonBase>
  );
};

export default Rf3Link
