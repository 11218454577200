import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { getFavorites } from "../actions/common";
import { startSetLinks } from "../actions/link";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid"
import { Link } from "react-router-dom";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FavoritesImg from "../images/favorites-low.jpg";
const styles = theme => ({
    card: {
        // minWidth: 275,
        // maxWidth: 360,
        // margin: 10,
        width: "100%",
        backgroundImage: `url(${FavoritesImg})`,
        backgroundSize: "cover",
        borderRadius: 10
    },
    title: {
        fontSize: 14,
    },
    list: {
        display: "block",
        padding: 10,
        maxWidth: 600,

    },
    content: {
        background: "linear-gradient(rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.8))",
        borderRadius: 10,
        minHeight: 160
    },
    white: {
        color: "#fff",
        // paddingLeft: 10,
    },
    whitePadding:{
        color: "#fff",
        paddingLeft: 10,
    },
    grey: {
        color: "#b3b3b3",
        paddingLeft: 30,
        lineHeight: "normal"
    },
    line: {
        width: 65,
        height: 3,
        backgroundColor: "rgb(255, 220, 0)",
        marginLeft: 10
        // marginTop: 12,
        // position: "absolute",
        // bottom: 18,
        // left: 26,
    },

});
class Favorites extends React.Component {
    componentDidMount() {

        if (this.props.common.user.isAuthenticated) {
            this.props.getFavorites(this.props.common.user.me.email, this.props.common.user.access_token)
            this.props.startSetLinks(this.props.common.user.access_token)
        }
    }
    render() {
        if (!this.props.link.forms) {
            return null;
        }
        const { classes } = this.props;
        return (
            <div style={{
                width: window.innerWidth < 360 ? "90%" : 360,
                margin: 10,
                borderRadius: 10,
                minHeight: 200
            }}>
                <Grid
                    container
                    direction="row"
                    justify="flex-start"
                    alignItems="flex-start"
                >
                    <Card className={classes.card} key={this.props.common.favorites.id}
                    >
                        <CardContent className={classes.content}>
                            <Typography lang="en" variant="h4" className={classes.whitePadding}>
                                {this.props.common.favorites.name}
                            </Typography>
                            <div className={classes.line} />
                            <List>
                                {this.props.common.favorites.props.favorites.map(p => (
                                    this.props.link.forms.map(f => {
                                        if (f.formId.S === p) {
                                            return (
                                                <ListItem className={this.props.classes.list} button key={p} component={Link} to={`form/${p}`}>
                                                    <Typography lang="en" color="secondary">{p}</Typography>
                                                    <Typography lang="en" variant="h6" className={classes.white}>{f.title.S}</Typography>
                                                    <Typography lang="en" variant="subtitle1" className={classes.grey}>{f.desc.S}</Typography >
                                                </ListItem>
                                            )

                                        }
                                    })
                                )

                                )}
                            </List>
                        </CardContent>
                    </Card>
                </Grid>
            </div >
        )
    }
}
function mapStateToPorps(state) {
    return {
        common: state.common,
        link: state.link,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        getFavorites: getFavorites,
        startSetLinks: startSetLinks,
    }, dispatch)
}
Favorites.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default connect(mapStateToPorps, mapDispatchToProps)(withStyles(styles)(Favorites));
