import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Web from "@material-ui/icons/Web";
// import { Link,a } from "react-router-dom";
import { ListItemIcon } from '@material-ui/core';
const styles = theme => ({
    list: {
        width: 250,
        backgroundColor: theme.palette.text.primary,
        position: "fixed",
        bottom: 0,
        // paddingBottom: 20
    },
    text: {
        color: theme.palette.common.white,
    },
    version: {
        color: theme.palette.common.grey,
        fontSize:"0.75em"
    },
});

class Footer extends React.Component {
    state = {
        left: false,
    };

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.list}>
                <List>
                    {this.props.isAuthenticated?<ListItem button key={"admin"} component="a" href="https://admin.forms.ramirent.fi">
                        <ListItemIcon>
                            <Web className={classes.text }/>
                        </ListItemIcon>
                        <ListItemText classes={{ primary: classes.text }} primary={"RamiForms Hallinta"} />
                    </ListItem>:""}
                    <ListItem key={"version"}>
                        <ListItemText classes={{ primary: classes.version }} primary={"Julkaistu: "+process.env.RELDATE} />
                    </ListItem>
                </List>
            </div>
        );
    }
}

Footer.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);