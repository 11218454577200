import {toggleLoader} from "./common";
export const addFilledForms = (data) => {
    return {
        type: 'ADD_FILLEDFORMS',
        data
    }

};

export const startSetFilledForms = (fillId, token) => {
    return (dispatch) => {
        const url = process.env.BASE_URL+"get-filled-form/" + fillId;
        dispatch(toggleLoader());
        fetch(url, {
            method: "GET",
            mode: "cors",
            headers: {
                "access_token": token ? token : "null"
            }
        }).then(response => response.json()
            .then(json => {
                // console.log(json);
                if(!json.message){
                    dispatch(addFilledForms({ filledForm: json }));
                }
                dispatch(toggleLoader());
            }))
    }
}