import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "react-router-dom";
import Footer from "./Footer";
const styles = theme => ({
  list: {
    width: 250,
  },
  drawer: {
    backgroundColor: theme.palette.primary.main,
  },
  text: {
    fontFamily: "Boing-Semibold",
    fontSize:25
  },
});

class SideDrawer extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            left: false,
          };
    }
  

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  render() {
    const { classes } = this.props;
    const sideList = (
      <div className={classes.list}>
        <List>
          <ListItem button key={"home"} component={Link} to={""}>
            <ListItemText classes={{ primary: classes.text }} primary={"RamiForms"} />
          </ListItem>
          <ListItem button key={"forms"} component={Link} to={"/forms"}>
            <ListItemText classes={{ primary: classes.text }} primary={"Lomakkeet"} />
          </ListItem>
          <ListItem button key={"filled"} component={Link} to={"/filled"}>
            <ListItemText classes={{ primary: classes.text }} primary={"Täyttämäni lomakkeet"} />
          </ListItem>
          <ListItem button key={"login"} component={Link} to={"/login"}>
            <ListItemText classes={{ primary: classes.text }} primary={this.props.isAuthenticated?"Käyttäjä":"Kirjaudu"}/>
          </ListItem>
        </List>
      </div>
    );
    return (
      <div>
        <IconButton color="inherit" onClick={this.toggleDrawer('left', true)}>
          <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          classes={{ paper: classes.drawer }}
          open={this.state.left}
          onOpen={this.toggleDrawer('left', true)}
          onClose={this.toggleDrawer('left', false)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            {sideList}
            <Footer isAuthenticated={this.props.isAuthenticated}/>
          </div>
        </SwipeableDrawer>
      </div>
    );
  }
}

SideDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SideDrawer);