import React from 'react';
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logout } from "../actions/common";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import {
  Grid,
  Button,
  Chip,
  Box,
  Collapse,
  IconButton,
  CircularProgress,
  Paper,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Table, 
  makeStyles,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';

const useStyles = makeStyles({
  head: {
    fontSize: 20
  },
  cell: {
    color: '#333'
  },
  loadingSpinner: {
    display: 'block',
    margin: 'auto',
    marginTop: 200
  },
  root: {
    '& > td': {
      borderBottom: 'unset',
    },
  },
  title: {
    fontSize: 14
  },
  tableMargin: {
    marginTop: 50
  }
});

const getRequest = (url, token, headers = {}) => {
  return new Request(url, {
    method: 'GET',
    mode: 'cors',
    headers: {
      access_token: token,
      ...headers
    }
  });
};

const maxSeqOrderId = (orders) => {
  return orders.reduce((maxCounter, order) => {
    if (order.id > maxCounter) {
      return order.id;
    } else {
      return maxCounter;
    }
  }, 0);
};

const isValidStatus = (status) => {
  const validStatuses = [200];
  return validStatuses.includes(status);
}

const getOrders = async (setOrders, setIsLoading, access_token, logout) => {
  const url = process.env.ORDER_API + 'get-order';
  try {
    setIsLoading(true);
    const response = await fetch(getRequest(url, access_token));
    if (isValidStatus(response.status)) {
      const orders = await response.json();
      setOrders(orders);
      setIsLoading(false);
    }
    if (response.status === 403) {
      logout();
      return;
    }
  } catch (e) {
    console.error(`Error while fetching orders from ${url}`);
    console.error(e);
    setOrders([]);
    setIsLoading(false);
  }
};

const getOrderCount = async (token) => {
  const url = process.env.ORDER_API + 'get-order-counter';
  const response = await fetch(getRequest(url, token));
  const { count } = await response.json();
  return count;
}

const priceText = (priceAmount, pricePerMonth) => {
  if (priceAmount) {
    return `${priceAmount}€ ${pricePerMonth ? '/kk' : '/päivä'}`;
  } else {
    return 'Ei hintaa';
  }
};
const ramiSafeMap = {
  'eiRamiTurvaa': 'Ei RamiTurvaa',
  'ramiTurvaAov': 'RamiTurva AOV',
  'ramiTurva': 'Täysi RamiTurva'
};
const formatRamiSafe = (ramiSafe) => ramiSafeMap[ramiSafe] ? ramiSafeMap[ramiSafe] : ramiSafe;

const downloadOrderPdf = async (orderNumber, token) => {
  const url = process.env.ORDER_API + 'get-pdf/';
  const req = getRequest(url + orderNumber + '.pdf', token, {'Content-Type': 'application/pdf', 'Accept': 'application/pdf'});
  const pdfBlob = await (await fetch(req)).blob();
  const file = window.URL.createObjectURL(pdfBlob);
  const a = document.createElement('a');
  a.href = file;
  a.setAttribute('download', orderNumber + '.pdf');
  a.click();
};

const Row = ({ order, classes, access_token }) => {

  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  }

  return <>
    <TableRow key={order.id} className={classes.root}>
      <TableCell>
        <IconButton size="medium" onClick={handleExpandClick}>
          {expanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      </TableCell>
      <TableCell className={classes.cell} scope="row">
        {order.customerName}
      </TableCell>
      <TableCell className={classes.cell}>{order.siteName}</TableCell>
      <TableCell className={classes.cell}>{order.siteId}</TableCell>
      <TableCell className={classes.cell}>{order.orderer}</TableCell>
      <TableCell className={classes.cell}>{order.seller}</TableCell>
      <TableCell className={classes.cell}>{new Date(order.created_at).toLocaleString()}</TableCell>
      <TableCell className={classes.cell}>{order.office}</TableCell>
      <TableCell className={classes.cell}>
        <IconButton size="medium" onClick={() => {downloadOrderPdf(order.ramiRentOrderNumber, access_token)}}>
          <PictureAsPdf />
        </IconButton>
      </TableCell>
    </TableRow>
    <TableRow>
      <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
        <Collapse in={expanded}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <h3>Sähköpostit lähetetty</h3>
              <table>
                <tbody>
                  <tr><td>Myyjälle</td><td><Chip label={order.sellerEmail} /></td></tr>
                  <tr><td>Asiakkaalle</td><td><Chip label={order.ordererEmail} /></td></tr>
                </tbody>
              </table>
            </Grid>
          </Grid>
          <h3>Tuotteet</h3>
          <TableContainer>
            <Box margin={1}>
              <Table size='small' className={classes.table}>
                <TableHead>
                  <TableRow>
                    <TableCell>Määrä</TableCell>
                    <TableCell>Tunniste</TableCell>
                    <TableCell>Nimi</TableCell>
                    <TableCell>Info</TableCell>
                    <TableCell>RamiTurva</TableCell>
                    <TableCell>Hinta</TableCell>
                    <TableCell>Yksikkkö</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    order.products.map((product, i) => {
                      return <TableRow key={product.name + i}>
                        <TableCell className={classes.cell}>{product.amount}</TableCell>
                        <TableCell className={classes.cell}>{product.productId}</TableCell>
                        <TableCell className={classes.cell}>{product.name}</TableCell>
                        <TableCell className={classes.cell}>{product.info}</TableCell>
                        <TableCell className={classes.cell}>{formatRamiSafe(product.ramiSafe)}</TableCell>
                        <TableCell className={classes.cell}>{priceText(product.price, product.perMonth)}</TableCell>
                        <TableCell className={classes.cell}>{product.unit}</TableCell>
                      </TableRow>
                    })
                  }
                </TableBody>
              </Table>
            </Box>
          </TableContainer>
        </Collapse>
      </TableCell>
    </TableRow>
  </>
}

let intervalId = null;

const shouldReloadOrders = (apiCount, maxOrderId) => apiCount > maxOrderId;

const allowedDomains = ['@digia.com', '@ramirent.fi'];

const isAllowedToViewOrderList = (user) => {
  const all = user && user.me && user.me.roles && user.me.roles.all;
  if (!all) {
    return false;
  }

  const usersEmail = (user && user.me && user.me.email) || '';
  return Object.keys(all).some((role) => allowedDomains.some(domain => usersEmail.includes(domain)) || user.me.roles.all[role].role_url === 'RamiForms\\RamiForms admin');
}

const canAccessOrdersTable = (props) => {
  return props.common.user.isAuthenticated && isAllowedToViewOrderList(props.common.user);
};

const OrdersTable = (props) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState(false);
  const [orders, setOrders] = React.useState([]);
  const token = props.common.user.access_token;

  React.useEffect(() => {
    if(canAccessOrdersTable(props)) {
      getOrders(setOrders, setIsLoading, token, props.logout);
    }
  }, [token]);

  React.useEffect(() => {
    if (orders.length > 0) {
      intervalId = setInterval(async () => {
        const apiCount = await getOrderCount(token);
        const maxOrderId = maxSeqOrderId(orders);
        if (shouldReloadOrders(apiCount, maxOrderId)) {
          clearInterval(intervalId);
          getOrders(setOrders, setIsLoading, token);
        }
      }, 30000);
    }
    return () => {
      clearInterval(intervalId);
    }
  }, [orders, token]);

  if (!canAccessOrdersTable(props)) {
    return <Redirect to={'/login'} />;
  }

  if (orders.length === 0 && isLoading) { //inital load
    return <Box m="auto">
      <CircularProgress className={classes.loadingSpinner} />
    </Box>;
  }

  return <Box m={5}>
    <Card>
      <CardContent>
      <Typography className={classes.title} color="textSecondary" gutterBottom>
          Tilaukset yhteensä
        </Typography>
        <Typography variant="h5" component="h2">
          {maxSeqOrderId(orders)}
        </Typography>
      </CardContent>
    </Card>
    <TableContainer component={Card} className={classes.tableMargin}>
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
          Sata viimeisintä tilausta
        </Typography>
      </CardContent>
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.head}></TableCell>
            <TableCell className={classes.head}>Asiakas</TableCell>
            <TableCell className={classes.head}>Työmaa</TableCell>
            <TableCell className={classes.head}>Työnumero</TableCell>
            <TableCell className={classes.head}>Tilaaja</TableCell>
            <TableCell className={classes.head}>Myyjä</TableCell>
            <TableCell className={classes.head}>Päivämäärä</TableCell>
            <TableCell className={classes.head}>Office</TableCell>
            <TableCell className={classes.head}>Lataa</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            orders.map((order) => <Row classes={classes} key={order.id} order={order} access_token={token}/>)
          }
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
};

function mapStateToPorps(state) {
  return {
    common: state.common
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
      logout
  }, dispatch);
}

export default connect(mapStateToPorps, mapDispatchToProps)(OrdersTable);