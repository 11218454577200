import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const VERSION_KEY = 'latestAppVersion';

const getLatestVersion = () => {
  return localStorage.getItem(VERSION_KEY);
}

const setLatestVersion = (versionString) => {
  localStorage.setItem(VERSION_KEY, versionString);
}

const clearLatestVersion = () => {
  localStorage.removeItem(VERSION_KEY);
}

class AppUpdateProvider extends React.Component {
  constructor() {
    super();
    this.state = {
      updatePromptOpen: false
    };
    this.updateIntervalId = setInterval(() => {
      this.checkForUpdates();
    }, 1000 * 60);
    clearLatestVersion();
  }

  promptReload() {
    this.setState({updatePromptOpen: true});
  }

  async reloadHandler() {
    const latestAppVersion = await this.fetchLatestVersion();
    setLatestVersion(latestAppVersion);
    location.reload();
  }

  postponeHandler() {
    const NOW_PLUS_TWO_HOURS_MS = (new Date).getTime() + 1000 * 3600 * 2;
    this.setState({
      postponedUntil: NOW_PLUS_TWO_HOURS_MS
    });
    this.handlePromtClose();
  }

  handlePromtClose() {
    this.setState({updatePromptOpen: false});
  }

  async fetchLatestVersion() {
    const manifestHeaders = {
      'cache-control': 'no-cache',
      'pragma': 'no-cache'
    };
    const response = await fetch('/manifest.json', { headers: manifestHeaders });
    const { latestAppVersion } = await response.json();
    return latestAppVersion;
  }

  async checkForUpdates() {
    if (new Date().getTime() < this.state.postponedUntil) {
      return;
    }
    try {
      const latestAppVersion = await this.fetchLatestVersion();
      const currentAppVersion = getLatestVersion();
      if (currentAppVersion === null) { // initial state, no app version is yet set
        setLatestVersion(latestAppVersion);
        return;
      } else if (currentAppVersion !== latestAppVersion) { // check if saved app version does not match what we got from manifest.json
        this.setState({
          pendingLatestVersion: latestAppVersion
        });
        this.promptReload();
      }
    } catch (e) { // fail silently as this is not required for the app to function. A fail might happen during a flaky connection or when switching networks.
      console.warn('Failed to fetch latest version.', e);
      return;
    }

  }

  render() {
    return <>
      <Dialog open={this.state.updatePromptOpen}>
        <DialogTitle id="alert-dialog-slide-title">
          Päivitys saatavilla
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Uusi RamiForms versio on saatavilla. Päivitä heti uusimpaan versioon valitsemalla Päivitä. Mikäli sinulla on lomakkeen täyttö kesken, voit lykätä päivitystä valitsemalla Päivitä myöhemmin.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.reloadHandler.bind(this)}>Päivitä</Button>
          <Button onClick={this.postponeHandler.bind(this)}>Päivitä myöhemmin</Button>
        </DialogActions>
      </Dialog>
      {this.props.children}
    </>;
  }
}

export default AppUpdateProvider;