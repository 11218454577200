import React from "react";
import FavoriteIcon from "@material-ui/icons/Star";
import StarIcon from "@material-ui/icons/StarBorder"
import IconButton from "@material-ui/core/IconButton";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { editFavorites } from "../actions/common";
class Togglefavorite extends React.Component {
    handleClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // console.log(this.props.formId)
        let favorites = this.props.common.favorites.props.favorites;
        if (favorites.includes(this.props.formId)) {
            favorites.splice(favorites.indexOf(this.props.formId), 1);
        } else {
            favorites.push(this.props.formId);
        }
        this.props.editFavorites({...this.props.common.favorites,props:{favorites:favorites}},this.props.common.user.access_token,this.props.common.user.me.email)
    }
    render() {
        return (
            <div>
                <IconButton onClick={this.handleClick}>
                    {this.props.common.favorites.props.favorites.includes(this.props.formId)?<FavoriteIcon color="secondary"/>:<StarIcon />}
                </IconButton>
            </div>
        )
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        editFavorites: editFavorites,
    }, dispatch)
}
export default connect(null, mapDispatchToProps)(Togglefavorite)