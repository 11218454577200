import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        overflowX: "auto"
    },
    table: {
        minWidth: 700
    },
    icons: {
        display: "flex",
        padding:"0px 10px"
    },
    cell:{
        padding:"0px 10px"
    }
});
class EditableRow extends React.Component {
    constructor(props) {
        super(props)
    }
    handleChange = (rowIndex, index) => event => {
        let row = this.props.row;
        let count = 0;
        let target = "";
        event.target.type === "checkbox" ? target = "checked" : target = "value"
        for (let cell in row) {
            if (count === index) {
                row[cell] = event.target[target];
            }
            count++;
        }
        this.props.onRowChange(rowIndex, row)
    };
   
    getType(cell) {
        let type = "text"
        this.props.fields.map(f => {
            if (f.label === cell) {
                type = f.type;
            }
        })
        return type;
    }
    render() {
        const { classes } = this.props;
        return (
            <TableRow key={this.props.index + "row"}>
                {Object.keys(this.props.row).map((cell, index) => {
                    return (
                        <EditableTableCell
                            key={"cell" + index}
                            value={this.props.row[cell]}
                            type={this.getType(cell)}
                            fieldName={index}
                            classes={this.props.classes}
                            id={`cell${this.props.index}-${index}`}
                            onCellValueChange={this.handleChange(this.props.index, index)}
                        />)
                })}
                <TableCell component="th" scope="row" className={classes.icons} key={"buttons" + this.props.index}>
                    <IconButton
                        key={"copy" + this.props.index}
                        onClick={()=>this.props.onRowCopy(this.props.index)}
                        variant="outlined"
                        className={classes.button}
                    >
                        <CopyIcon></CopyIcon>
                    </IconButton>
                    <IconButton
                        key={"delete" + this.props.index}
                        onClick={()=>this.props.onRowDelete(this.props.index)}
                        variant="outlined"
                        className={classes.button}
                    >
                        <DeleteIcon></DeleteIcon>
                    </IconButton>
                </TableCell>
            </TableRow>
        );
    }
};
const EditableTableCell = ({ id, value, type, fieldName, onCellValueChange,classes }) => {
    
    switch (type) {
        case "checkbox":
            return (
                <TableCell key={id} className={classes.cell}>
                    <Checkbox
                        checked={value}
                        onChange={onCellValueChange}
                        value={fieldName.toString()}
                    />
                </TableCell>)
        default:
            console.log('defaulut');
            return (
                <TableCell key={id} className={classes.cell}>
                    <TextField
                        type={type}
                        onChange={onCellValueChange}
                        id={fieldName.toString()}
                        defaultValue={value}
                        margin="normal"
                    />
                </TableCell>
            );

    }

};

class SimpleTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            rowsChanges: {},
            rows: [],
            newRow: [],
            initRow: [],
        };
        this.props.fields.map(f => {
            if (f.label !== "") {
                this.state.newRow[f.label] = ""
            }
        })
        this.state.initRow = this.state.newRow;
    }
    handleAddRow(event) {
        // console.log(event.key)
        if(event.key ==="Enter"){
            event.preventDefault();
        event.stopPropagation();
            let rows = this.state.rows;
            rows.push(this.state.newRow)
            this.setState({ rows: rows, newRow: this.state.initRow })
            this.props.handleTableChange(rows);
        }
        
    }
    handleDelete(rowIndex){
        let rows = this.state.rows;
        rows.splice(rowIndex,1)
        this.setState({
            rows: rows
        })
        this.props.handleTableChange(rows);
    }
    handeRowCopy(rowIndex){
        let rows = this.state.rows;
        rows.push(rows[rowIndex]);
        this.setState({
            rows: rows
        })
        this.props.handleTableChange(rows);
    }
    handleRowChange(rowIndex, value) {
        let rows = this.state.rows;
        rows[rowIndex] = value;
        this.setState({
            rows: rows
        })
        this.props.handleTableChange(rows);
    }
    handleNewRowChange = name => event => {
        let target = "";
        event.target.type === "checkbox" ? target = "checked" : target = "value"
        this.setState({
            newRow: {
                ...this.state.newRow,
                [name]: event.target[target]
            }
        })
    }
    render() {
        const { classes, fields } = this.props;
        return (
            <Paper className={classes.root}>
                <Table className={classes.table} onKeyDown={this.handleAddRow.bind(this)}>
                    <TableHead>
                        <TableRow>
                            {fields.map(f => {
                                return (<TableCell key={f.label}>{f.label}</TableCell>)
                            })}
                            <TableCell key="empty" className={classes.cell}></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {this.state.rows.map((row, index) => {
                            return <EditableRow
                                key={`row-${index}`}
                                row={row}
                                fields={this.props.fields}
                                index={index}
                                classes={classes}
                                onRowChange={this.handleRowChange.bind(this)}
                                onRowDelete = {this.handleDelete.bind(this)}
                                onRowCopy = {this.handeRowCopy.bind(this)}
                            />
                        })}
                        <TableRow key="newRow">
                            {fields.map((f, index) => {
                                switch (f.type) {
                                    case "checkbox":
                                        return (
                                            <TableCell key={`${f.label}${index}`} className={classes.cell}>
                                                <Checkbox
                                                    checked={this.state.newRow[f.label]}
                                                    onChange={this.handleNewRowChange(f.label)}
                                                    value={f.label}
                                                />
                                            </TableCell>)
                                    default:
                                        return (
                                            <TableCell key={`${f.label}${index}`} className={classes.cell}>
                                                <TextField
                                                    type={f.type}
                                                    onChange={this.handleNewRowChange(f.label)}
                                                    id={f.label}
                                                    value={this.state.newRow[f.label]}
                                                    margin="normal"
                                                />
                                            </TableCell>)
                                }
                            })}
                            <TableCell component="th" scope="row" className={classes.cell}>
                                <IconButton
                                    onClick={this.handleAddRow.bind(this)}
                                    variant="outlined"
                                    className={classes.button}
                                    // onKeyDown={this.handleAddRow.bind(this)}
                                >
                                    <AddIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        );
    }
}

export default withStyles(styles)(SimpleTable);
