import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { Link } from "react-router-dom";
import ButtonBase from "@material-ui/core/ButtonBase";

const styles = theme => ({
    button: {
        display: "block",
        maxWidth: 900
    },
    paper: {
        padding: theme.spacing(2),
        // textAlign: 'center',
        color: theme.palette.text.secondary,
        margin: 10
    },
    params: {
        display: "flex",
        flexWrap: "wrap"
    },
});
const Params = (props) => {
    try {
        return (
            <div style={{ padding: 10 }}>
                <Typography variant="caption" color="textSecondary">{props.field}</Typography>
                <Typography>{props.value.S}</Typography>
            </div>
        )

    } catch (error) {
        return ""
    }

}
class FilledRow extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            updated: "ei koskaan"
        }
    }

    render() {
        let values = []
        const { classes, filled } = this.props;
        const dont = ["fillId", "fileName", "formId", "searchParams", "searchWords", "timeStamp", "title", "generatePdf", "filledBy", "filledByName"]
        Object.entries(filled).forEach(([f, value]) => {
            if (!dont.includes(f)) {
                values.push({ field: f, value: value });
            }
        })
        try {


            return (
                <ButtonBase
                    component={Link} to={"filled/" + filled.fillId.S}
                    focusRipple
                    className={classes.button}
                >
                    <Paper key={filled.fillId.S} className={classes.paper}>
                        <Typography variant="caption" color="secondary">{new Date(parseInt(filled.timeStamp.S)).toLocaleString()}</Typography>
                        <Typography variant="h6" >{filled.title.S}</Typography>
                        <div className={classes.params}>
                            {values.map((v, i) => (
                                <Params key={"i_" + i} field={v.field} value={v.value} />
                            ))}
                        </div>
                    </Paper>
                </ButtonBase>
            );
        } catch (error) {
            return(<div/>)
        }
    }
}

FilledRow.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(FilledRow);