import { toggleLoader } from "./common";
export const addLink = (link) => {
    // console.log(link);
    return {
        type: 'ADD_LINK',
        link
    }

};
export const addLinks = (links) => {
    // console.log(link);
    return {
        type: 'ADD_LINKS',
        links
    }

};
export const addTree = (tree) => {
    return {
        type: "ADD_TREE",
        tree
    }
}
export const startGetTree = (token,email) => {
    // console.log("get tree");
    return (dispatch) => {
        dispatch(startSetLinks(token,email));
        // if (token) {
            dispatch(toggleLoader());
            const url = process.env.BASE_URL + "get-tree";

            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "access_token": token || ""
                },

            }).then(response => response.json()
                .then(json => {
                    let data = json.body;
                    // console.log(data);
                    dispatch(addTree(data.tree));
                }).then(dispatch(toggleLoader())))
        // }
    }

}
export const startSetLinks = (token,email) => {
    return (dispatch) => {
        dispatch(toggleLoader());
        
        const url = process.env.BASE_URL + "get-list";
        if (token) {
            fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                    "access_token":token
                },body:JSON.stringify({
                    user:email
                })

            }).then(response => response.json()
                .then(json => {
                    let data = json.body;
                    // let links = data.map((link)=>{
                    //    return {link:link}
                    // })
                    dispatch(addLinks(data))
                }).then(dispatch(toggleLoader())))
        } else {
            // console.log("not authenticated set links");
            fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json; charset=utf-8",
                },

            }).then(response => response.json()
                .then(json => {
                    let data = json.body;
                    // let links = data.map((link)=>{
                    //    return {link:link}
                    // })
                    dispatch(addLinks(data))
                }).then(dispatch(toggleLoader())))
        }

    }
}