import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { addData, startSetForms, toggleData } from "../actions/data";
import { startSetLinks, startGetTree } from "../actions/link";
import { getFavorites } from "../actions/common";
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Grid from "@material-ui/core/Grid";
import { Link } from "react-router-dom";
import { ExpansionPanel, ExpansionPanelSummary, ExpansionPanelDetails } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ToggleFavorite from "./ToggleFavorite";

const styles = theme => ({
    root: {
        width: '100%',
        marginTop: theme.spacing(3),

    },
    list: {
        display: "block",
        padding: 10,
        maxWidth: 600,
        color: 'rgb(0, 50, 135)'
    },
    tab: {
        fontFamily: "Boing-Bold",
        padding: 20,
        textAlign: "center",
    },
    link: {
        padding: 10,
        textAlign: "center",
    },
    details: {
        display: "block"
    },
    span:{
        display:"flex"
    }
});
function compareTitle(a, b) {
    if (a.title.S.toUpperCase() < b.title.S.toUpperCase())
        return -1 ;
    if (a.title.S.toUpperCase() > b.title.S.toUpperCase())
        return 1;
    return 0;
}
function compareName(a, b) {
    if (a.name.toUpperCase() < b.name.toUpperCase())
        return -1 ;
    if (a.name.toUpperCase() > b.name.toUpperCase())
        return 1;
    return 0;
}
class FormsList extends React.Component {
    linkClicked = formId => () => {
        let contains = false;
        this.props.data.map((d) => {
            if (d.form.formId.toString() === formId) {
                contains = true;
            }
        })
        if (!contains) {
            this.props.startSetForms(formId);
        } else {
            this.props.toggleData(parseInt(formId));
        }
    };
    folder = (folder, isSub) => {
        if (Object.keys(folder).length >= 1) {
            let sortedfolders = folder.folders;
            if (!sortedfolders) {
                return null;
            }
            sortedfolders.sort(compareName);
            if (window.location.hash.includes("outokumpu")) {
                if (folder.name === "Outokumpu") {
                    return (
                        <List>
                            {sortedfolders.length>0? sortedfolders.map(folder => (
                                this.folder(folder, true)
                            )) : ""}
                            {this.form(folder.forms)}
                        </List>
                    )
                } else if (isSub) {
                    return (
                        <List className={this.props.classes.list} key={folder.name}>
                            <ExpansionPanel square={true}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography lang="en" variant="h4" >{folder.name}</Typography >
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={this.props.classes.details}>
                                    <List>
                                        {sortedfolders.length>0? sortedfolders.map(folder => (
                                            this.folder(folder, true)
                                        )) : ""}
                                        {this.form(folder.forms)}
                                    </List>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </List>)
                } else {
                    return (sortedfolders.length>0? sortedfolders.map(folder => (
                        this.folder(folder)
                    )) : "")
                }
            }else if (window.location.hash.includes("metsafibre")) {
                if (folder.name === "metsafibre") {
                    return (
                        <List>
                            {sortedfolders.length>0? sortedfolders.map(folder => (
                                this.folder(folder, true)
                            )) : ""}
                            {this.form(folder.forms)}
                        </List>
                    )
                } else if (isSub) {
                    return (
                        <List className={this.props.classes.list} key={folder.name}>
                            <ExpansionPanel square={true}>
                                <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                    <Typography lang="en" variant="h4" >{folder.name}</Typography >
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails className={this.props.classes.details}>
                                    <List>
                                        {sortedfolders.length>0? sortedfolders.map(folder => (
                                            this.folder(folder, true)
                                        )) : ""}
                                        {this.form(folder.forms)}
                                    </List>
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </List>)
                } else {
                    return (sortedfolders.length>0? sortedfolders.map(folder => (
                        this.folder(folder)
                    )) : "")
                }
            }
            else if (folder.name !== "root") {
                return (
                    <List className={this.props.classes.list} key={folder.name}>
                        <ExpansionPanel square={true}>
                            <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                <Typography lang="en" variant="h4" >{folder.name}</Typography >
                            </ExpansionPanelSummary>
                            <ExpansionPanelDetails className={this.props.classes.details}>
                                <List>
                                    {sortedfolders.length>0? sortedfolders.map(folder => (
                                        this.folder(folder)
                                    )) : ""}
                                    {this.form(folder.forms)}
                                </List>
                            </ExpansionPanelDetails>
                        </ExpansionPanel>
                    </List>
                )
            } else {
                return (
                    <ListItem className={this.props.classes.list}>

                        {
                            sortedfolders.length>0? sortedfolders.map(folder => (
                                this.folder(folder)
                            )) : ""
                        }
                        {this.form(folder.forms)}
                    </ListItem>
                )
            }
        }
    }

    componentDidMount = () => {

        if (this.props.common.user.me) {
            this.props.startGetTree(this.props.common.user.access_token, this.props.common.user.me.email);
            this.props.getFavorites(this.props.common.user.me.email, this.props.common.user.access_token)
        } else {

            this.props.startGetTree(this.props.common.user.access_token);
        }
    }
    form = (forms) => {
        let formInfo;
        const from = window.location.hash.includes("outokumpu") ? "outokumpu" : window.location.hash.includes("metsafibre") ?"metsafibre":"form";
        let sortedForms = this.props.link.forms;
        if (!sortedForms) {
            return null;
        }
        sortedForms.sort(compareTitle)
        return (
            sortedForms.map(form => {
                return forms.map(f => {
                    if (form.formId.S === f) {
                        formInfo = { title: form.title.S, desc: form.desc.S, visibility: form.visibility ? form.visibility.S : "-" }
                        return (
                            <ListItem className={this.props.classes.list + this.props.classes.span} button key={f} component={Link} to={`${from}/${f}`}>
                                {this.props.common.user.isAuthenticated?<ToggleFavorite formId={f} common={this.props.common} />:""}
                                <div>
                                    <Typography lang="en" color="secondary">{formInfo.visibility === "draft" ? "Luonnos (näkyy vain sinulle)" : ""}</Typography>
                                    <Typography lang="en" variant="h6">{formInfo.title} - {f}</Typography>
                                    <Typography lang="en" variant="subtitle1" color="textSecondary">{formInfo.desc}</Typography >
                                </div>
                            </ListItem>)
                    }
                })

            })

        )
    }
    publicForm = () => {
        let sortedForms = this.props.link.forms;
        if (!sortedForms) {
            return null;
        }
        sortedForms.sort(compareTitle)
        return (
            sortedForms.map(form => {
                return (
                    <ListItem className={this.props.classes.list} button key={form.formId.S} component={Link} to={`/form/${form.formId.S}`}>
                        <Typography lang="en" variant="h6">{form.title.S} - {form.formId.S}</Typography>
                        <Typography lang="en" variant="subtitle1" color="textSecondary">{form.desc.S || "-"}</Typography >
                    </ListItem>)
            })
        )
    }

    renderList = () => {
        if (window.location.hash.includes("outokumpu")||window.location.hash.includes("metsafibre")) {
            
            return (this.folder(this.props.link.tree))
        } else {
            if (this.props.common.user.isAuthenticated) {
                return (
                    this.folder(this.props.link.tree)
                )
            } else if (!this.props.common.user.isAuthenticated) {
                return (this.publicForm())
            }
        }
    }
    render() {
        const { classes } = this.props;
        return (
            <div>

                <Typography className={classes.tab} color="textPrimary" variant="h3">Lomakkeet</Typography>
                {this.props.common.user.isAuthenticated || window.location.hash.includes("outokumpu")||window.location.hash.includes("metsafibre") ? "" : <Typography lang="en" className={classes.link} color="secondary" component={Link} to={"/login"}>Kirjaudu sisään nähdäksesi lisää lomakkeita</Typography>}

                <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="center"
                >
                    {this.renderList()}
                </Grid>
            </div>
        );
    }
}
function mapStateToPorps(state) {
    return {
        link: state.link,
        data: state.data,
        common: state.common,
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        addData: addData,
        startSetForms: startSetForms,
        toggleData: toggleData,
        startSetLinks: startSetLinks,
        startGetTree: startGetTree,
        getFavorites: getFavorites,
    }, dispatch)
}
FormsList.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default connect(mapStateToPorps, mapDispatchToProps)(withStyles(styles)(FormsList));