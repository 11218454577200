import React from 'react';


class LoadingView extends React.Component {
  render() {

    return (
      <div >
        <h1>Forms</h1>
      </div>
    );
  }

}


export default LoadingView;