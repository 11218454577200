export default (state = [], action) => {
    function compare(a, b) {
        let rev = action.desc ? -1 : 1;

        if (a[action.by].S < b[action.by].S)
            return -1 * rev;
        if (a[action.by].S > b[action.by].S)
            return 1 * rev;
        return 0;


    }
    switch (action.type) {
        case 'ADD_FILLED':
            return action.filled;
        case 'SORT_FILLED':
            let newState = [];
            let rest = [];
             state.map(f => {
                if (f[action.by]) {
                    newState.push(f);
                }else{
                    rest.push(f);
                }
            });
            newState.sort(compare);
            return newState.concat(rest);
        default:
            return state;
    }
};
