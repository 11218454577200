import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

const getContent = (success, error) => {
    if (error) {
        return <><DialogContentText>Lomaketta ei ole lähetetty. Voit yrittää korjata lomakkeessa olevaa virhettä valitsemalla "Jatka muokkausta".</DialogContentText><pre>{error}</pre></>;
    } else {
        return <DialogContentText>{success}</DialogContentText>;
    }
};

const getTitle = (title, error) => {
    if (error) {
        return 'Tapahtui virhe';
    } else {
        return title;
    }
}

class AlertDialogSlide extends React.Component {
    render() {
        return (
            <Dialog open={this.props.open || false}>
                <DialogTitle id="alert-dialog-slide-title">
                    {getTitle(this.props.title, this.props.error)}
                </DialogTitle>
                <DialogContent>
                    {getContent(this.props.success, this.props.error)}
                </DialogContent>
                <DialogActions>
                    {this.props.error && <Button onClick={this.props.handleClose}>Jatka muokkausta</Button>}
                    <Button onClick={this.props.resetHandler}>Täytä uusi lomake</Button>
                    <Button onClick={this.props.gotoFrontPage}>Siirry etusivulle</Button>
                </DialogActions>
            </Dialog>
        );
    }
}

export default AlertDialogSlide;