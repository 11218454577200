import { toggleLoader, setFileLoading } from "./common";

export const addData = (data) => {
    return {
        type: 'ADD_DATA',
        data
    }

};
export const toggleData = formId => ({
    type: 'TOGGLE_DATA',
    formId
});
//Get form s3
export const startSetForms = (fileName, token) => {
    return (dispatch) => {
        dispatch(toggleLoader());
        const url = process.env.BASE_URL + "get-form/" + fileName;
        // console.log(fileName);
        return fetch(url, {
            method: "GET",
            mode: "cors",
            headers: {
                "access_token": token ? token : "null"
            }
        }).then(response => response.json())
            .then(dispatch(toggleLoader()))
            .then(data => {
                if (data.status === 'error.invalidtoken') {
                    return Promise.reject({status: data.status});
                }
                dispatch(addData({ form: data }))
                return data;
            })
    }
}
export const sendOrderForm = (formData, token, dispatch) => {
    let dataToSend = {
        userName:formData.filledByName,
        timestamp:formData.timeStamp,
        customerName: "",
        customerNumber: "",
        orderId: "",
        contractId: "",
        siteId: "",
        siteName: "",
        deliveryAddress: "",
        town:"",
        deliveryInfo: "",
        orderer: "",
        ordererPhone: "",
        ordererEmail: "",
        deliveryType: "",
        recipient: "",
        recipientPhone: "",
        seller: "",
        sellerPhone: "",
        office: "",
        returnDate: "",
        capabilityConfirmed: "false",
        deliveryPrice: "",
        fastDelivery: false,
        requiresInstallation: false,
        info: "",
        orderCopyEmails: "", 
        products: []
    }
    const tableNames = ["id", "name", "ramisafe", "amount", "unit", "price", "permonth", "info"]
    formData.form.map(d => {
        if (d.orderKey) {
            switch (d.type) {
                case "dropdown":
                case "automation-options":
                    d.props.map(p => {
                        if (d.orderKey === "orderCopyEmails") {
                            dataToSend[d.orderKey] = p.value
                        } else {
                            if (d.value === p.value) {
                                dataToSend[d.orderKey] = p.label
                            }
                        }
                    })
                    break;
                case "table":
                    d.value.map((v, i) => {
                        let row = {};
                        v.map((x, ind) => {
                            let key = tableNames[ind]
                            row[key] = x;
                        })
                        console.log(row);
                        dataToSend.products.push(row);
                    })
                    break;
                default:
                    dataToSend[d.orderKey] = d.value
                    break;
            }
        }
    })
    dispatch(toggleLoader());
    dispatch(setFileLoading(100))
    const url = process.env.ORDER_API + "add-order";
    return fetch(url, {
        method: "POST",
        body: JSON.stringify(dataToSend)

    }).then(response => response.json())
        .then(dispatch(toggleLoader()))
        .then(data => {
            if (data.message === null) {
                return "error"
            }
            console.log(data)
            return data;
        })

}
function dataURItoBlob(dataURI) {
    var byteString = atob(dataURI.split(',')[1]);

    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]

    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    var bb = new Blob([ab], { "type": mimeString });
    return bb;
}
export const sendForm = (data, token) => {

    return (dispatch) => {
        let percentage = 0;
        let files = 0;
        data.form.map(f => {
            if (f.type === "file") {
                try {
                    // elements++
                    if (Array.isArray(f.value)) {
                        files += f.value.length;
                    }

                } catch (error) {
                    console.log(error)
                }

            }
        });
        return Promise.all(data.form.map((f, i) => {
            if (f.type == "file") {
                if (f.value !== "" && f.value !== "null") {
                    return Promise.all(f.value.map((v, ind) => {
                        const url = process.env.BASE_URL + "put-image";
                        percentage++;
                        dispatch(setFileLoading((percentage / (files * 8)) * 100))
                        const blob = dataURItoBlob(v)
                        return fetch(url, {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json; charset=utf-8",
                                "access_token": token
                            },
                            body: JSON.stringify({ key: `${data.fillId}-${i}-${ind}.${blob.type.split("/").pop()}` }),

                        })
                            .then(response => response.json()
                                .then((res) => {
                                    percentage++;
                                    dispatch(setFileLoading((percentage / (files * 8)) * 100))
                                    let formData = new FormData();
                                    formData.append("file", blob);
                                    return fetch(res.url, {
                                        method: "PUT",
                                        headers: {
                                            "Content-Type": blob.type,
                                        },
                                        body: blob,

                                    }).then(res => {
                                        let value = res.url.split("?");
                                        percentage += 6;
                                        dispatch(setFileLoading((percentage / (files * 8)) * 100))
                                        return value[0];
                                    }
                                    )
                                }))
                    })).then(res => {
                        f.value = res
                        return res
                    })
                }
            }
        })).then((res) => {
            if (data.formId === "FI1025") {
                return sendOrderForm(data, token,dispatch)
            }
            else {
                dispatch(toggleLoader());
                dispatch(setFileLoading(100))
                const url = process.env.BASE_URL + "add-filled";
                return fetch(url, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=utf-8",
                        "access_token": token
                    },
                    body: JSON.stringify(data),

                })
                    .then(response => response.json()
                        .then((res) => {
                            dispatch(toggleLoader())
                            dispatch(setFileLoading(0))
                            return res
                        }))
            }



        })
    }
}
