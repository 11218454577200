import React from "react";
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { startSetFilledForms } from "../actions/filledForms";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import BuildFilled from "./BuildFilled";
import IconButton from "@material-ui/core/IconButton";
import BackIcon from "@material-ui/icons/ArrowBack";
import {withRouter} from "react-router-dom";
const styles = theme => ({
    root: {
        margin: 10,
        flexGrow: 1,
    },
});
class FilledForm extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        let contains = false;
        this.props.filledForms.map((d) => {
            if (d.filledForm.fillId.toString() === this.props.fillId) {
                contains = true;
            }
        })
        if (!contains) {
            this.props.startSetFilledForms(this.props.fillId, this.props.common.user.access_token);
        }
    }
    handleClick = () => {
        this.props.history.goBack();
    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                {this.props.filledForms.map(d => {
                    if (d.filledForm.fillId === this.props.fillId) {
                        return (
                            <div key ={d.filledForm.title}>
                                <IconButton key="backbutton" onClick={this.handleClick}>
                                    <BackIcon></BackIcon>
                                </IconButton>
                                <BuildFilled key={d.filledForm.title} model={d.filledForm} from={"view"} user={this.props.common.user} />
                            </div>
                        )
                    }
                })}
            </div>
        )
    }
}
function mapStateToPorps(state) {
    return {
        filledForms: state.filledForms,
        common: state.common
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        startSetFilledForms: startSetFilledForms,
    }, dispatch)
}
FilledForm.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToPorps, mapDispatchToProps)(withStyles(styles)(withRouter(FilledForm)));