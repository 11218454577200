import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { REHYDRATE } from 'redux-persist';
const yesterday = new Date(new Date().getTime() - 86400000);
const date = yesterday.toISOString().split('T')
const INITIAL_STATE = {
    user: { isAuthenticated: false },
    loading: false,
    spinner: false,
    fileLoading: 0,
    hasEdits: false,
    settings: [],
    favorites: {
        id: "",
        name: "Suosikit",
        props: {
            favorites: [
            ]
        },
        type: "user"
    },
    searchFilled: {
        updated:"nyt",
        searchWord: "",
        title: "",
        startDate: date[0],
        endDate: ""
    }
}

const CommonReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REHYDRATE:
            return {
                ...state,
                loading: false
            };
        case 'TOGGLE_LOADER':
            return {
                ...state,
                loading: !state.loading
            };
        case 'SET_LOADER':
                return {
                    ...state,
                    loading: action.data
                };
        case 'TOGGLE_SPINNER':
            return {
                ...state,
                spinner: !state.spinner
            };
        case 'SET_SPINNER':
            return {
                ...state,
                spinner: action.data
            };
        case 'LOGIN':
            return {
                ...state,
                user: action.user
            }
        case 'LOGOUT':
            return {
                ...state,
                user: { isAuthenticated: false }
            }
        case "SET_SETTINGS":
            return {
                ...state,
                settings: action.data
            }
        case "SET_FAVORITES":
            return {
                ...state,
                favorites: action.data
            }
        case "SET_FILELOADER":
            return {
                ...state,
                fileLoading: action.percentage
            }
        case "SET_SEARCHFILLED":
            return {
                ...state,
                searchFilled: action.data
            }
        case 'SET_HAS_EDITS':
            return {
                ...state,
                hasEdits: action.hasEdits
            }
        default:
            return state;
    }
};
const persistConfig = {
    key: 'common',
    storage: storage,
    blacklist: ['loading', 'spinner', 'searchFilled', 'hasEdits']
};

export default persistReducer(persistConfig, CommonReducer);