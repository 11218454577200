import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import Typography from '@material-ui/core/Typography';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import { downloadFile } from "../actions/filled";
import { connect } from "react-redux";
import Doc from "../images/pdf.svg";
// import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap"
    },
    paper: {
        padding: 20,
        width: "50vw",
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: "90vw"
        }
    },
    img: {
        width: "15em",
        padding: "1em",
    },
    value: {
        color: "#000",
        paddingLeft: 10
    },
    element: {
        padding: 5
    },
    divider: {
        height: 2,
        marginTop: 15,
        marginBottom: 15,
        backgroundColor: theme.palette.text.primary
    },
    divirerDiv: {
        // marginLeft: -20,
        // marginRight: -20
    },
});

class BuildFilled extends React.Component {
    constructor(props) {
        super(props);
    }
    renderElement = (element) => {
        const { classes } = this.props;
        let toReturn = [];
        switch (element.type) {
            case "label":
                toReturn.push(<Typography key={element.key} variant={element.fontSize || "h5"}>{element.label}</Typography>)
                break;
            case "divider":
                toReturn.push(
                    <div key={element.key} className={classes.divirerDiv}>
                        <Typography lang="en" gutterBottom align="center" color="textPrimary" variant="overline" >{element.label}</Typography>
                        <Divider className={classes.divider} />
                    </div>)
                break;
            case "radio":
            case "dropdown":
            case "automation-options":
                toReturn.push(element.props.map(p => {
                    if (element.value === p.value) {
                        return (
                            <div key={element.key} className={classes.element}>
                                <Typography lang="en" variant="h6">{element.label}</Typography>
                                <Typography lang="en" className={classes.value}>{p.label}</Typography>
                            </div>)
                    }
                }))
                break;
            case "checkbox":
            case "autoFillCheckbox":
                toReturn.push(
                    <FormControlLabel key={element.key} control={
                        <Checkbox
                            checked={element.value}
                            // onChange={this.handleChange(m.key)}
                            value={element.key}
                        />}
                        label={element.label}
                    />)
                break;
            case "drawing":
                return (
                    <div key={element.key} className={classes.element}>
                        <Typography lang="en" variant="h6">{element.label}</Typography>
                        <img key={element.key} className={classes.img} src={element.value} />
                    </div>
                )
            case "file":
                if (element.value !== "" && element.value !== "null" && typeof element.value === "object") {
                    toReturn.push(
                        <div key={element.key} className={classes.element}>
                            <Typography lang="en" variant="h6">{element.label}</Typography>
                            {element.value.map((url, i) => (
                                <a key={element.key + "_a_" + i} href={url} target="_blank">
                                    <img key={element.key + "_" + i} className={classes.img} src={url.split(".").pop() === "jpg" || url.split(".").pop() === "png" || url.split(".").pop() === "jpeg" ? url : Doc} />
                                </a>
                            )
                            )}
                        </div>)
                } else {
                    toReturn.push(
                        <div key={element.key} className={classes.element}>
                            <Typography lang="en" variant="h6">{element.label}</Typography>
                            <Typography lang="en" className={classes.value}>Ei tiedostoja</Typography>
                        </div>)
                }

                break;
            default:
                toReturn.push(
                    <div key={element.key} className={classes.element}>
                        <Typography lang="en" variant="h6">{element.label}</Typography>
                        <Typography lang="en" className={classes.value}>{element.value}</Typography>
                    </div>)
                break;

        }
        if (element.comment) {
            toReturn.push(<div key={element.key + "comment"} className={classes.element}>
                <Typography lang="en" color="secondary" variant="subtitle1">{element.commentLabel || "Kommentti"}</Typography>
                <Typography lang="en" className={classes.value}>{element.comment}</Typography>
            </div>)
        }
        return toReturn
    }
    render() {
        const { classes } = this.props;
        let title = this.props.model.title || "Dynamic Form";
        return (
            <div className={classes.root}>
                <Paper key="info" className={classes.paper}>
                    <Typography lang="en" variant="h5">Tietoja</Typography>
                    <Typography lang="en" variant="body2">{this.props.model.fillId}</Typography>
                    <Typography lang="en" variant="body2">{this.props.model.filledBy}</Typography>
                    <Typography lang="en" variant="body2">{this.props.model.filledByName}</Typography>
                    <Typography lang="en" variant="body2">{new Date(parseInt(this.props.model.timeStamp)).toLocaleString()}</Typography>
                    {this.props.model.generatePdf ?
                        <Button key={"load-button" + this.props.model.fillId} size="small" variant="contained" color="secondary" onClick={() => downloadFile("pdf", this.props.model.fillId + ".json.pdf", this.props.common.user.access_token)}>Lataa pdf</Button>
                        : ""
                    }
                </Paper>
                <Paper key="data" className={classes.paper}>
                    <Typography lang="en" variant="h4">{title}</Typography>
                    <Typography variant="body2">{this.props.model.formId}</Typography>
                    {this.props.model.form.map(element => (
                        this.renderElement(element)
                    ))}
                </Paper>
            </div>
        )
    }
}
function mapStateToPorps(state) {
    return {
        common: state.common
    }
}
BuildFilled.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default connect(mapStateToPorps)(withStyles(styles)(BuildFilled));