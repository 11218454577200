import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import DrawCanvas from "./DrawCanvas";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SignatureImg from "../images/GearUp_signature_colors.png"
import { Typography } from "@material-ui/core";
import Slide from '@material-ui/core/Slide';
import withMobileDialog from '@material-ui/core/withMobileDialog';
const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        border: "1px solid black"
    },
    img: {
        width: 50,
        height: "auto"
    },
    signed: {
        width: 300,
    },
    button: {
        display: "flex",

    },
    dialog: {
        width: "100%",
        margin: 0
    }


});
function Transition(props) {
    return <Slide direction="up" {...props} />;
}

class DrawDialog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            img: "null",
        }

    }
    handleClickOpen = () => {
        console.log(this.props.fullScreen)
        document.body.style = { overflow: "hidden", position: "fixed" }
        const elem = document.documentElement;
        this.setState({ open: true });
        if (this.props.fullScreen) {
            if (elem.requestFullscreen) {
                elem.requestFullscreen();
            } else if (elem.mozRequestFullScreen) { /* Firefox */
                elem.mozRequestFullScreen();
            } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
                elem.webkitRequestFullscreen();
            } else if (elem.msRequestFullscreen) { /* IE/Edge */
                elem.msRequestFullscreen();
            }
        }

    };

    handleClose = () => {
        this.setState({ open: false });
        if (this.props.fullScreen) {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { /* Firefox */
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { /* IE/Edge */
                document.msExitFullscreen();
            }
        }
    };
    clear = () => {
        this.saveableCanvas.clear();
    }
    save = () => {
        const img = this.saveableCanvas.save();
        this.setState({ img: img });
        this.handleClose();
        this.props.handleChange(img)
    }
    render() {
        const { classes, fullScreen } = this.props;
        return (
            <div>
                <Typography variant="h6">{this.props.label}</Typography>
                <Button color="secondary" onClick={this.handleClickOpen} className={classes.button}>
                    <img className={this.state.img === "null" ? classes.img : classes.signed} src={this.state.img === "null" ? SignatureImg : this.state.img}></img>
                </Button>

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullScreen={fullScreen}
                    // fullWidth
                    // classes={{ paper: classes.dialog }}
                    TransitionComponent={Transition}
                >
                    <DialogTitle id="form-dialog-title">{this.props.label}</DialogTitle>
                    <DialogContent>
                        <DrawCanvas onRef={ref => (this.saveableCanvas = ref)} />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.clear} variant="outlined">
                            Tyhjennä
                        </Button>
                        <Button onClick={this.handleClose} variant="outlined" color="primary">
                            Peruuta
                        </Button>
                        <Button onClick={this.save} variant="contained" color="secondary">
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
DrawDialog.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(withMobileDialog()(DrawDialog));





