import React from "react";
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioButtonGroup from "./RadioButtonGroup";
import { withSnackbar } from 'notistack';
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { toggleLoader, toggleSpinner, setSpinner, setHasEdits } from "../actions/common";
import { sendOrderForm, sendForm } from "../actions/data";
import Dropdown from "./Dropdown";
import AlertPopup from "./AlertPopup";
import Divider from "@material-ui/core/Divider";
import DrawDialog from "./DrawDialog";
import Autocomplete from "./Autocomplete";
import InputTable from "./InputTable";
import FormTable from "./FormTable";
import FileElement from "./FileElement";
import MobileTable from "./MobileTable";
import MobileTableCard from "./MobileTableCard";
import FormInput from "./FormInput";
import FormControl from '@material-ui/core/FormControl';

const clone = (obj) => JSON.parse(JSON.stringify(obj));

const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "center"
    },
    paper: {
        padding: 20,
        // width: "50vw",
        [theme.breakpoints.down('sm')]: {
            width: "90vw"
        }
    },
    form: {
        display: "grid",
        gridTemplateColumns: "repeat(1, 1fr)",
        gridGap: "0px 30px",
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: "repeat(1, 1fr) !important",
        }
    },
    element: {
        [theme.breakpoints.down('sm')]: {
            gridArea: "auto !important"
        },
        maxWidth: "50em"
    }, dividerElement: {
        [theme.breakpoints.down('sm')]: {
            gridArea: "auto !important"
        },
        maxWidth: "100%"
    },
    button: {
        margin: theme.spacing(1),
        fontFamily: "Boing-SemiBold",
        textTransform: "none",
    },
    sendButton: {
        gridColumn: 1,
    },
    rightIcon: {
        marginLeft: theme.spacing(1),
    },
    divider: {
        height: 2,
        marginTop: 15,
        marginBottom: 15,
        backgroundColor: theme.palette.text.primary,
        [theme.breakpoints.down('sm')]: {
            gridArea: "auto !important"
        },
    },
    divirerDiv: {
        marginLeft: -20,
        marginRight: -20
    },
    label: {
        marginTop: 10,
        marginBottom: 10,
    },
    checkboxGroup: {
        width: '100%'
    }
});

const setValue = (id, value) => document.getElementById(id).value = value;

const prefillValues = () => {
    // prefiller, only for testing purposes.
    setValue('key_1565178815232', 'Oy Rakentaja AB');
    setValue('key_1588591639337', 'Oy Rakentaja AB ASIAKAS');
    setValue('key_1565179217948', 'Oy Rakentaja AB ASIAKAS');
    setValue('key_1565179239100', 'aleksanteri.vode@gmail.com');
    setValue('key_1565179010323', 'NUM1337');
    setValue('key_1565179050113', 'Iso Roba 3');
    setValue('key_1572333587437', 'Helsinki');
    setValue('key_1565179105060', '2021-01-01');
    setValue('key_1588577168227', '2022-01-01');
    setValue('key_1565179380679', '040-123456');
    setValue('key_1565179367603', 'Aleksanteri Negru-Vode');
    setValue('key_1589283407101', 'aleksanteri.vode@digitalentconsulting.fi');
    setValue('key_1565179418524', '2022-01-01');
    setValue('key_1565179256114-required', 'r_0');
};

window.prefillValues = prefillValues;

const validEmail = (email) => {
    return email.includes('@') && email.split('@')[1].includes('.');
} 

class BuildForm extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            alertpopupOpen: false,
            ok: { reason: [], submit: false },
            required: [],
            shrink: false,
            error: null,
            success: null
        };
        if (this.props.model) {
            this.props.model.form.map((m) => {
                switch (m.type) {
                    case "text":
                    case "number":
                    case "email":
                    case "date":
                    case "time":
                        const r = React.createRef();
                        this[m.key] = r;
                        break;
                    case "label":
                        break;
                    case "automation-options":
                        this.state[m.key] = "none"
                        if (m.required) {
                            this.state.required.push(m.key);
                        }
                        break;
                    case "table":
                        this.state[m.key] = [];
                        break;

                    case "autoFillCheckbox":
                        m.props.map(p => {
                            this.state[p.value] = ""
                        })
                    default:
                        this.state[m.key] = m.type === "checkbox" || m.type === "autoFillCheckbox" ? m.value || false : m.value || ""
                        if (m.required) {
                            this.state.required.push(m.key);
                        }
                        break;
                }
            });
        }
    }

    componentWillUnmount() {
        this.props.setHasEdits(false);
    }

    handleChange = name => event => {
        let target = "";
        event.target.type === "checkbox" ? target = "checked" : target = "value"
        this.setState({
            [name]: event.target[target],
        });
        this.props.setHasEdits(true);
    };

    setDefaultValue = name => defaultValue => {
        this.setState({
            [name]: defaultValue,
        });
    };
    handleDrawChange = name => value => {
        this.setState({
            [name]: value,
        });
        this.props.setHasEdits(true);
    }
    handleRadioChange = name => event => {
        if (this.state.ok.reason.includes(name)) {
            let newReason = this.state.ok.reason;
            newReason.splice(newReason.indexOf(name), 1);
            let submit = newReason.length === 0 ? true : false;
            this.setState({ ok: { reason: newReason, submit: submit } });
        }
        this.setState({
            [name]: event,
        });
        this.props.setHasEdits(true);
    };
    checkSubmit = () => {
        let notOk = false;
        this.state.required.map(r => {
            if (!this.state[r] || this.state[r] === "none") {
                this.props.model.form.map(f => {
                    if (f.key === r) {
                        this.props.enqueueSnackbar(f.label + " on pakollinen kenttä", { variant: "error" });
                    }

                })
                let newReason = this.state.ok.reason;
                newReason.push(r);
                this.setState({ ok: { ...this.state.ok, reason: newReason } })
                notOk = true;
            }
        })
        if (this.props.model.formId === "FI1025") {
            this.props.model.form.map(f => {
                if (f.type === "table" && this.state[f.key].length < 1) {
                    this.props.enqueueSnackbar("Et ole lisännyt yhtään tuotetta", { variant: "error" });
                    notOk = true;
                }
                if (f.type === 'email') {
                    try { // this try catch block is because the state handling is so messed up.
                        const email = this[f.key].state.value;
                        if(!validEmail(email)) {
                            this.props.enqueueSnackbar(`Kenttä: ${f.label}, arvo: ${email} ei ole validi sähköpostiosoite.`, { variant: "error" });
                            notOk = true;
                        }
                    } catch (e) {
                        console.error('Unable to validate email field', f.key, e);
                    } 
                }
                if (f.type === 'automation-options' && f.orderKey === "orderCopyEmails" && this.state[f.key] && this.state[f.key].value) {
                    const selectedSendOrderId = this.state[f.key].value;
                    const option = this.props.options.find(option => option.id === f.value)
                    const orderCopyEmails = option.props.find(prop => prop.key === selectedSendOrderId)
                    if (orderCopyEmails && orderCopyEmails.value && !notOk) {
                        notOk = !window.confirm(`Olet lähettämässä tilauksen osoitteeseen ${orderCopyEmails.value}`);
                    } else {
                        notOk = true;
                    }
                }
            })
        }
        return notOk;
    }
    handleAlertClose = () => {
        this.setState({ alertpopupOpen: false });
    }

    onSubmit = (e) => {
        e.preventDefault();
        e.stopPropagation();

        
        if (this.checkSubmit()) {
            return false;
        }
        try {
            this.props.toggleSpinner();

            let date = new Date();
            let data = clone(this.props.model);
            if (data.productIdElement && data.productIdElement !== "none") {
                data.productId = this[data.productIdElement].value;
            } else {
                data.productId = 'id';
            }
            data.filledBy = this.props.common.user.me ? this.props.common.user.me.email : "null";
            data.filledByName = this.props.common.user.me ? this.props.common.user.me.fullname : "null";
            data.timeStamp = date.getTime().toString();
            data.form = this.props.model.form.map(f => {
                let value;
                let labels = [];
                if (this[f.key] !== undefined) {
                    if(this[f.key].value){
                        value = this[f.key].value;
                    }else if(this[f.key].state){
                        value = this[f.key].state.value
                    }else{
                        value = ""
                    }
                }
                else if (this.state[f.key] !== undefined) {
                    value = this.state[f.key]
                }
                else {
                    value = undefined
                }
                if (f.type === 'table') {
                    labels = clone(f.value);
                }
                if (f.type === "automation-options") {
                    let toReturn;
                    this.props.options.map(o => {
                        if (f.value === o.id) {
                            o.props.map(p => {
                                if (p.key === this.state[f.key].value) {
                                    toReturn = {
                                        ...f,
                                        props: [p],
                                        value: p.value
                                    }
                                }
                            })
                        }
                    })
                    if (!toReturn) {
                        toReturn = { ...f, props: [{ value: "none", label: "none" }], value: "none" }
                    }
                    return toReturn;
                }
                if (this[f.key + "_comment"]) {
                    return ({
                        ...f,
                        comment: this[f.key + "_comment"].value,
                        value: value,
                        labels
                    })
                } else {
                    return ({
                        ...f,
                        value: value,
                        labels
                    })
                }
            });
            const customTime = new Date(date.getTime() - new Date().getTimezoneOffset() * 60 * 1000).toISOString().substr(0, 19).replace('T', '-').replace(":", "_").replace(":", "_") + ":" + new Date().getMilliseconds().toString();
            data.fillId = data.productId + "-" + data.formId + "-" + customTime;//date.toISOString().replace("T", "-").replace("Z", "");
            const token = this.props.user.access_token ? this.props.user.access_token : "null";
            this.props.sendForm(data, token).then(res => {
                if (res.success === true) {
                    this.props.setHasEdits(false);
                    this.setSuccessState('Täyttämäsi lomake on lähetetty onnistuneesti! Voit aloittaa uuden lomakkeen täyttämisen tai siirtyä etusivulle.');
                } else {
                    this.setErrorState(res.message);
                }
                this.setState({ alertpopupOpen: true });
            });
        } catch (error) {
            this.props.enqueueSnackbar("Jotain meni pieleen", { variant: "error" });
        }
    };

    setErrorState = (message) => {
        this.setState({ error: JSON.stringify(message, null, 2) });
        this.props.setSpinner(false);
    }

    setSuccessState = (message) => {
        this.setState({ success: message, error: null });
        this.props.setSpinner(false);
    }

    handleResetForm = () => {
        location.reload();
    };

    renderComment = (comment, commentLabel, key) => {
        if (comment === "") {
            return (
                <TextField
                    key={key + "_comment"}
                    type="text"
                    label={commentLabel || "Kommentti"}
                    inputRef={el => this[key + "_comment"] = el}
                    margin="normal"
                    multiline
                    variant="outlined"
                    fullWidth
                />
            )
        }
    }
    handleTableChange = name => value => {
        this.setState({
            [name]: value,
        });
        this.props.setHasEdits(true);
    }
    handleAutoFill = name => event => {
        try {
            if (event.target.checked) {
                this.props.model.form.map(f => {
                    if (f.key === name) {
                        f.props.map(p => {
                            // <note>
                            // checks if the given value needs to be parsed to a number removing any non numeric signs.
                            // this is important when the target field is a number but the value is a phone number such as 040-123123.
                            // minus sign would not work when using it as a value.
                            let valueToBeUpdated = this.props.common.user.me[p.label];
                            const formElement = this.props.model.form.find((formElement) => {
                                return formElement.type === 'number';
                            });
                            if (formElement) {
                                const matchedNumbers = valueToBeUpdated.match(/\d+/g);
                                if (matchedNumbers && matchedNumbers.length > 0) {
                                    valueToBeUpdated = matchedNumbers.join('');
                                }
                            }
                            this.setState({
                                [p.value]: valueToBeUpdated,
                                [name]: event.target.checked,
                                shrink: !this.state.shrink
                            });
                            this.props.setHasEdits(true);
                        })
                    }
                })
            } else {
                this.props.model.form.map(f => {
                    if (f.key === name) {
                        f.props.map(p => {
                            this.setState({
                                [p.value]: "",
                                [name]: event.target.checked,
                                shrink: !this.state.shrink
                            });
                        })
                    }
                })
            }
        } catch (error) {
            console.log(error)
        }
    }
    renderForm = () => {
        const { classes } = this.props;
        let edit = this.props.edit;
        try {
            let model = this.props.model.form;
            let fromUI = model.map((m) => {
                let key = m.key;
                let type = m.type || "text";
                let properties = m.props || {};

                const comp = () => {
                    switch (m.type) {
                        case "label":
                            return (<Typography lang="en" className={classes.label} variant={m.fontSize || "h5"} key={m.key}>{m.label}</Typography>)
                        case "divider":
                            return (
                                <div key={m.key} className={classes.divirerDiv}>
                                    <Typography lang="en" gutterBottom align="center" color="textPrimary" variant="overline" >{m.label}</Typography>
                                    <Divider className={classes.divider} />
                                </div>
                            )
                        case "checkbox":
                            return (
                                <FormControl component="fieldset" className={classes.checkboxGroup}>
                                    <FormControlLabel key={m.key} control={
                                        <Checkbox
                                            checked={this.state[m.key]}
                                            onChange={this.handleChange(m.key)}
                                            value={`${m.key}`}
                                            onKeyPress={(e) => {
                                                if (e.key === 'Enter') {
                                                    e.preventDefault();
                                                }
                                            }}
                                        />}
                                        label={m.label}
                                        labelPlacement="start"
                                    />
                                </FormControl>
                                )
                        case "radio":
                            return <RadioButtonGroup
                                key={m.key}
                                model={m}
                                value={this.state[m.key]}
                                onRadioChange={this.handleRadioChange(m.key)}
                                error={this.state.ok.reason}
                            />
                        case "dropdown":
                            return <Dropdown
                                key={m.key}
                                name={m.key}
                                label={m.label}
                                value={this.state[m.key]}
                                options={m.props}
                                required={m.required || false}
                                onDropdownChange={this.handleChange}
                                addNone />
                        case "number":
                            return (
                                <TextField
                                    label={m.label}
                                    key={"i" + m.key}
                                    type={type}
                                    value={this.state[m.key]}
                                    onChange={this.handleChange(m.key)}
                                    inputRef={el => this[m.key] = el}
                                    margin="normal"
                                    inputProps={{ step: m.step || 1 }}
                                    required={m.required || false}
                                    fullWidth
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            );
                        case "drawing":
                            return (
                                <DrawDialog key={m.key} label={m.label} handleChange={this.handleDrawChange(m.key)} />
                            );
                        case "date":
                        case "time":
                            return (
                                <TextField
                                    label={m.label}
                                    key={"i" + m.key}
                                    type={type}
                                    defaultValue={m.value}
                                    id={m.key}
                                    inputRef={el => this[m.key] = el}
                                    margin="normal"
                                    props={properties}
                                    onChange={() => {this.props.setHasEdits(true);}}
                                    required={m.required || false}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    fullWidth
                                    onKeyPress={(e) => {
                                        if (e.key === 'Enter') {
                                            e.preventDefault();
                                        }
                                    }}
                                />
                            );
                        case "automation-options":
                            let options = [];
                            this.props.options.map(o => {
                                if (m.value === o.id) {
                                    options = JSON.parse(JSON.stringify(o.props))
                                }
                            })
                            options.map(o => {
                                o.value = o.key
                            })
                            return <Autocomplete
                                defaultValueName={m.defaultValueName}
                                key={m.key}
                                name={m.key}
                                settings={{ label: m.label, placeholder: "Valitse" }}
                                value={this.state[m.key]}
                                options={options}
                                required={m.required || false}
                                onAutocompleteChange={this.handleDrawChange}
                                addNone
                            />
                        case "table":
                            if (m.value[0] && m.value[0].label) {
                                return (
                                    <MobileTable
                                        fields={m.value}
                                        handleTableChange={this.handleTableChange(m.key)}
                                        tableProps={m.props.tableProps}
                                    />
                                );
                            }
                            return ""
                        // }
                        case "file":
                            return (
                                <FileElement handleChange={this.handleDrawChange(m.key)} label={m.label} />
                            )
                        case "autoFillCheckbox":
                            return (
                                <FormControlLabel key={m.key} control={
                                    <Checkbox
                                        checked={this.state[m.key]}
                                        onChange={this.handleAutoFill(m.key)}
                                        value={`${m.key}`}
                                        onKeyPress={(e) => {
                                            if (e.key === 'Enter') {
                                                e.preventDefault();
                                            }
                                        }}
                                    />}
                                    label={m.label}
                                />
                            )
                        default:
                            return (
                                <FormInput
                                    handleChange={this.handleChange(m.key)}
                                    setDefaultValue={this.setDefaultValue(m.key)}
                                    autoFill={this.state[m.key]}
                                    model={m}
                                    ref={el => this[m.key] = el}
                                />
                            );
                    }
                }

                const styles = {
                    gridColumn: m.styles ? m.styles.column : "auto",
                    gridRow: m.styles ? m.styles.row : "auto",
                }
                if (m.type === "table") {
                    styles.maxWidth = "100%"
                    styles.overflow = "auto"
                }
                return (
                    <div key={key} style={styles} className={m.type !== "divider" ? classes.element : classes.dividerElement}>
                        {comp()}
                        {this.renderComment(m.comment, m.commentLabel, m.key)}
                    </div>
                )
            });
            return fromUI;

        } catch (error) {
            console.log(error);
            return (<Typography variant="body1">No content found</Typography>);
        }

    }

    handleGotoFrontPage() {
        window.location.href = '/';
    }

    render() {
        const { classes } = this.props;
        let title = this.props.model.title || "Dynamic Form";
        return (
            <div className={classes.root}>
                <AlertPopup
                    open={this.state.alertpopupOpen}
                    handleClose={this.handleAlertClose}
                    title="Lomake lähetetty"
                    error={this.state.error}
                    success={this.state.success}
                    resetHandler={this.handleResetForm}
                    gotoFrontPage={this.handleGotoFrontPage}
                >
                    
                </AlertPopup>
                <Paper className={classes.paper}>
                    <Typography lang="en" variant="h4">{title}</Typography>
                    <Typography variant="body2">id: {this.props.model.formId}</Typography>
                    <form style={{
                        gridTemplateColumns: `repeat(${this.props.model.styles ? this.props.model.styles.columns : 1}, 1fr)`
                    }
                    }
                        className={classes.form}
                        onSubmit={this.onSubmit}>
                        {this.renderForm()}
                        <div key="sendbutton" className={classes.sendButton}>
                            <Button variant="contained" color="secondary" type="submit" className={classes.button}>
                                Lähetä
                        </Button>
                        </div>
                    </form>
                </Paper>
            </div>
        )
    }
}
function mapStateToPorps(state) {
    return {
        common: state.common
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleLoader: toggleLoader,
        toggleSpinner: toggleSpinner,
        setSpinner: setSpinner,
        sendOrderForm: sendOrderForm,
        sendForm: sendForm,
        setHasEdits: setHasEdits
    }, dispatch)
}
BuildForm.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default connect(mapStateToPorps, mapDispatchToProps)(withStyles(styles)(withSnackbar(BuildForm)));