import React from "react";
import { connect } from "react-redux"
import Modal from "@material-ui/core/Modal";
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from "@material-ui/core/Typography";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
// import LinearProgress from '@material-ui/core/LinearProgress';
const styles = theme => ({
    root: {
        position: "fixed",
        top: "30%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center"

    },
    content: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
    },
    smaller:{
        display: "flex",
        flexDirection: "column",
        padding:"2em",
        alignItems: "center",
        backgroundColor:"#fff",
        borderRadius:10,
    },
    span:{
        display:"flex"
    },
    smallCircular:{
        marginLeft:5
    },
    linear: {
        width: "50%"
    },
    text: {
        color: "#fff"
    }

});
class Loader extends React.Component {
    render() {
        const { classes } = this.props;
        let variant = "static"
        let infoText = "Lähetetään lomaketta"
        if (this.props.common.fileLoading >= 100 || this.props.common.fileLoading === 0) {
            variant = "indeterminate";
            infoText = "Ladataan..."
        }
        return (
            <Modal
                open={this.props.common.spinner} 
                // open
                className={classes.root}
                disableAutoFocus>
                <div className={classes.content}>
                    <div className={classes.smaller}>
                        <span className={classes.span}>
                        <Typography variant="body1" color="secondary">Älä keskeytä!</Typography>
                        <CircularProgress className={classes.smallCircular} size={20} variant="indeterminate" color="secondary"/>
                        </span>
                        <CircularProgress size={65} variant={variant} value={this.props.common.fileLoading} />
                        <Typography variant="h5" >{infoText}</Typography>
                    </div>
                    {/* <LinearProgress className={classes.linear} color="secondary" variant="determinate" value={this.props.common.fileLoading} /> */}
                </div>
            </Modal>
        )
    }
}
function mapStateToPorps(state) {
    return {
        common: state.common
    }
}
Loader.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToPorps)(withStyles(styles)(Loader));