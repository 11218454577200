import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
    root: {
        padding:15
    },
    formControl: {
        width:"100%"
    },
    group: {
        margin: `${theme.spacing(1)}px 0`,
    },
});

class RadioButtonGroup extends React.Component {
    constructor(props){
        super(props)
       
    }
    handleChange = event => {
        this.setState({ value: event.target.value });
        this.props.model.props.map(o =>{
            if(o.value===event.target.value){
                this.props.onRadioChange(o.value);
            }
        })
    };

    render() {
        const { classes } = this.props;
        return (
                <FormControl error = {this.props.error.includes(this.props.model.key)} component="fieldset" className={classes.formControl} required={this.props.model.required||false}>
                    {/* <FormLabel component="legend">Options</FormLabel> */}
                    <Typography variant="h6">{this.props.model.label}{this.props.model.required?" *":""}</Typography>
                    <RadioGroup
                        aria-label="buttonGroup"
                        name="buttonGroup"
                        className={classes.group}
                        value={this.props.value}
                        onChange={this.handleChange}
                        onKeyPress={(e) => {
                            if (e.key === 'Enter') {
                                e.preventDefault();
                            }
                        }}
                    >
                        {
                            this.props.model.props.map(option => {
                            return(
                            <FormControlLabel
                                key={option.value}
                                value={option.value}
                                control={<Radio color="primary" />}
                                label={option.label}
                                labelPlacement="start"
                            />
                            );
                        })}
                    </RadioGroup>
                   {this.props.error.includes(this.props.model.key)?<FormHelperText>Valitse yksi vaihtoehto</FormHelperText>:""}
                </FormControl>
        );
    }
}

RadioButtonGroup.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(RadioButtonGroup);

// [{"value": "safe", "label": "Kunnossa"},{"value": "notSafe","label": "Ei kunnossa"},{"value": "notInUse", "label": "Ei käytössä"}]