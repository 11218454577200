import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Collapse from '@material-ui/core/Collapse';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import classnames from 'classnames';


const styles = theme=> ({
    card: {
        minWidth: 275,
        margin: 10,
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    actions:{
        padding: "0px 4px"
    },
    content: {
        display: "grid",
        gridTemplateColumns: "1fr",
        paddingBottom: 0
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
});

class MobileTableCard extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            expanded: false,
            src: ""
        }
    }
    handleExpandClick = () => {
        this.setState(state => ({ expanded: !state.expanded }));
    };
    render() {
        const { classes } = this.props;
        return (
            <div key={this.props.index+"_card"}>
                <Card className={classes.card}>
                    <CardContent className={classes.content}>
                        {/* <Typography lang="en" color="textPrimary" gutterBottom>
                            {`${this.props.value[3]} ${this.props.value[4]} ${this.props.value[1]}`}
                        </Typography> */}
                        {/* <IconButton
                            className={classnames(classes.expand, {
                                [classes.expandOpen]: this.state.expanded,
                            })}
                            onClick={this.handleExpandClick}
                            aria-expanded={this.state.expanded}
                            aria-label="Show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton> */}
                        {/* <Collapse in={this.state.expanded}>
                            <CardContent> */}
                            {this.props.value.map((v, i) => {
                                
                                    if (this.props.fields[i].type === "checkbox") {
                                        return (
                                            <FormControlLabel key={"e_"+i} control={
                                                <Checkbox
                                                    checked={v}
                                                    value={`value_${v}`}
                                                />}
                                                label={this.props.fields[i].label}
                                            />
                                        )
                                    }
                                    return (
                                        <div key={"e_"+i}>
                                            <Typography lang="en" className={classes.title} color="textSecondary" gutterBottom>
                                                {this.props.fields[i].label}
                                            </Typography>
                                            <Typography lang="en" color="textPrimary" gutterBottom>
                                                {v}
                                            </Typography>
                                        </div>)
                                
                            })}
                            {/* </CardContent>
                        </Collapse> */}
                    </CardContent>
                    <CardActions className={classes.actions}>
                        <IconButton
                            key={`edit`}
                            onClick={() => this.props.handleEdit(this.props.index)}
                            variant="outlined"
                        >
                            <EditIcon />
                        </IconButton>
                        <IconButton
                            key={`copy`}
                            onClick={() => this.props.handleCopy(this.props.index)}
                            variant="outlined"
                        >
                            <CopyIcon />
                        </IconButton>
                        <IconButton
                            key={`delete`}
                            onClick={() => this.props.handleDelete(this.props.index)}
                            variant="outlined"
                        >
                            <DeleteIcon></DeleteIcon>
                        </IconButton>
                    </CardActions>
                </Card>

            </div>
        );

    }
}

MobileTableCard.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default (withStyles(styles)(MobileTableCard));