import React from "react";
import {
    HashRouter as Router,
    Route,
    Link,
    Redirect,
    withRouter
} from "react-router-dom";
import NavTabs from "./NavTabs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Form from "./Form";
import Typography from "@material-ui/core/Typography";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { login } from "../actions/common";
import Login from "./Login";
import Home from "./Home";
import Grid from "@material-ui/core/Grid";
import Loader from "./Loader";
import { startSetLinks } from "../actions/link";
import FormsList from "./FormsList";
import FilledForms from "./FilledForms";
import FilledForm from "./FilledForm";
import OrderTable from './OrderTable';

const style = {
    tab: {
        fontFamily: "Boing-Bold",
        padding: 20,
        textAlign: "center",
    }
}

const PrivateRoute = ({ component: Component, common, style, links, ...rest }) => (
    <Route
        {...rest}
        render={props => (
            common.user.isAuthenticated === true
                ? <Component {...props} {...style} {...links} />
                : <Redirect to={{
                    pathname: '/login',
                    state: { from: props.location }
                }} />
        )} />
)

class AppRouter extends React.Component {
    
    render() {
        return (
            <Router>
                <div>
                    <Loader />
                    <NavTabs auth={this.props.common.user} />
                    <Route exact path="/" component={HomeSite} />
                    <Route path="/profile" component={Login} />
                    <Route path="/login" component={Login} />
                    {/* <Route common = {this.props.common} style={style} links={{links:this.props.link}} path="/forms" component={FormsList}  /> */}
                    <Route path="/forms" render={(props) => <ListOfForms {...props} />} />
                    <Route path="/filled" render={(props) => <FilledSite {...props} />} />
                    <Route path="/tilaustieto" component={() => window.location.href = process.env.RF3_URL + "tilaustieto"} />
                    <Route path="/outokumpu" render={(props) => <OutokumpuForms {...props} />} />
                    <Route path="/metsafibre" render={(props) => <OutokumpuForms {...props} />} />
                    <Route path="/tilaukset" component={() => window.location.href = process.env.RF3_URL + "tilaukset"} />
                    <Route common={this.props.common} path="/form" component={Forms} />
                </div>
            </Router>
        );
    }
}
const FilledSite = ({ match }) => (
    <div>
        <Route path={`${match.path}/:id`} render={({ match }) => <FilledForm fillId={match.params.id} />} />
        <Route
            exact
            path={match.path}
            render={() => <FilledForms />}
        />
    </div>
);
const HomeSite = () => <Home />
const ListOfForms = () => (
    <FormsList />
);
const Tilaustieto = () => (
    <Form formId={"FI1025"}/>

);
const OutokumpuForms = ({ match }) => (
    <div>
        <Route path={`${match.path}/:id`} render={({ match }) => <Form formId={match.params.id} />} />
        <Route
            exact
            path={match.path}
            render={() => <FormsList/>}
        />
    </div>

);
const Forms = ({ match }) => (

    <div>
        <Route path={`${match.path}/:id`} render={({ match }) => <Form formId={match.params.id} />} />
        <Route
            exact
            path={match.path}
            render={() => <h3>Please select the form.</h3>}
        />
    </div>
);
function mapStateToPorps(state) {
    return {
        common: state.common,
        link: state.link

    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        login: login,
        startSetLinks: startSetLinks,

    }, dispatch)
}
export default connect(mapStateToPorps, mapDispatchToProps)(AppRouter, Login);