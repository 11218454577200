export default {
    "typography": {
        "useNextVariants": true,
        "fontFamily":["Boing-Regular","Boing-Semibold","Boing-Bold"]
    },
    "palette": {
        "common": {
            "black": "#000",
            "white": "#fff",
            "grey":" #D2D7DC"
        },
        "background": {
            "paper": "#fff",
            "default": "#fafafa"
        },
        "primary": {
            "light": "rgba(255, 220, 0, 0.5)",
            "main": "rgba(255, 220, 0, 1)",
            "dark": "rgba(229, 198, 0, 1)",
            "contrastText": "rgba(0, 50, 135, 1)"
        },
        "secondary": {
            "light": "#FFAB63",
            "main": "rgba(255, 150, 60, 1)",
            "dark": "rgba(80, 200, 170, 1)",
            "contrastText": "#fff"
        },
        "error": {
            "light": "#e57373",
            "main": "#f44336",
            "dark": "#d32f2f",
            "contrastText": "#fff"
        },
        "text": {
            "primary": "rgb(0, 50, 135)",
            "secondary": "rgba(0, 0, 0, 0.54)",
            "disabled": "rgba(0, 0, 0, 0.38)",
            "hint": "rgba(0, 0, 0, 0.38)",
            "white": "#fff"
        },

    },
    "rules":{
        "logoHeight":80,
    }
}
