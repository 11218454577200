import React from 'react';
import { Provider } from 'react-redux';
import AppRouter from "./components/AppRouter";
import { startSetLinks } from "./actions/link"
import ReactDOM from 'react-dom';
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import style from "./components/theme";

import { SnackbarProvider } from "notistack";
import { PersistGate } from 'redux-persist/lib/integration/react';
import LoadingView from "../src/components/LoadingView";
import { persistor, store } from './store';
import * as Sentry from '@sentry/browser';
import { RewriteFrames } from '@sentry/integrations';
import AppUpdateProvider from '../src/components/AppUpdateProvider';

import "./fonts/fonts.scss";
import "./style/custom-styles.scss";

const isLocalhost = () => location.hostname.includes('localhost');

if (!isLocalhost()) {
  Sentry.init({
    dsn: "https://a91dc697823e45038c5396ef1efd8d89@sentry.io/1806598",
    integrations: [new RewriteFrames()],
    release: process.env.RELDATE
  });
}

const defaultTheme = createMuiTheme(style)
const { breakpoints, typography: { pxToRem } } = defaultTheme

const theme = {
  ...defaultTheme,
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: "5rem",
        wordWrap: "break-word",
        overflowWrap: "break-word",

        WebkitHyphens: "auto",
        MozHyphens: "auto",
        hyphens: "auto",
        [breakpoints.down("xs")]: {
          fontSize: "3rem"
        }
      },
      h2: {
        fontSize: "4rem",
        wordWrap: "break-word",
        overflowWrap: "break-word",

        WebkitHyphens: "auto",
        MozHyphens: "auto",
        hyphens: "auto",
        [breakpoints.down("xs")]: {
          fontSize: "3rem"
        }
      },
      h3: {
        fontSize: "3rem",
        wordWrap: "break-word",
        overflowWrap: "break-word",

        WebkitHyphens: "auto",
        MozHyphens: "auto",
        hyphens: "auto",
        [breakpoints.down("xs")]: {
          fontSize: "2.5rem"
        }
      },
      h4: {
        fontSize: "2.125rem",
        wordWrap: "break-word",
        overflowWrap: "break-word",

        WebkitHyphens: "auto",
        MozHyphens: "auto",
        hyphens: "auto",
        [breakpoints.down("xs")]: {
          fontSize: "1.75rem"
        }
      },
      h5: {
        fontSize: "2.0rem",
        wordWrap: "break-word",
        overflowWrap: "break-word",

        WebkitHyphens: "auto",
        MozHyphens: "auto",
        hyphens: "auto",
        [breakpoints.down("xs")]: {
          fontSize: "1.5rem"
        }
      },
      h6: {
        fontSize: "1.5rem",
        wordWrap: "break-word",
        overflowWrap: "break-word",

        WebkitHyphens: "auto",
        MozHyphens: "auto",
        hyphens: "auto",
        [breakpoints.down("xs")]: {
          fontSize: "1.25rem"
        }
      }
    }
  }
}

const jsx = (
  <Provider store={store}>
    <MuiThemeProvider theme={theme}>
      <PersistGate loading={<LoadingView />} persistor={persistor}>
        <div>
          <SnackbarProvider maxSnack={3} disableWindowBlurListener>
            <AppUpdateProvider>
              <AppRouter/>
            </AppUpdateProvider>
          </SnackbarProvider>
        </div>
      </PersistGate>
    </MuiThemeProvider>
  </Provider>
)
let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, document.getElementById('root'));
    hasRendered = true;
  }
};

renderApp();