import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
const styles = theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        border: "1px solid black"
    },


});
class DrawCanvas extends React.Component {
    constructor(props) {
        super(props)
        this.ctx = {}
        this.canvas = {}
        this.state = {
            isDrawing: false,
            isEmpty: true,
        }
    }

    componentDidMount() {
        
        this.canvas = this.refs.canvas;
        this.ctx = this.canvas.getContext("2d")
        this.ctx.scale(5, 5);
        this.ctx.lineJoin = "round";
        this.ctx.lineCap = "round";

        this.ctx.lineWidth = 3;
        this.props.onRef(this)
            

    }
    componentWillUnmount() {
        this.props.onRef(undefined)
    }
    onmousedown = (e) => {
        this.setState({ isDrawing: true, isEmpty: false })
        const { x, y } = this.getPointerPos(e);
        this.ctx.moveTo(x, y);
    }

    handleMove = e => {
        if (this.state.isDrawing) {
            const { x, y } = this.getPointerPos(e);
            this.ctx.lineTo(x, y);
            this.ctx.stroke();

        }
    };

    onmouseup = (e) => {
        e.preventDefault();
        this.setState({ isDrawing: false })
    };
    clear() {
        this.ctx.clearRect(
            0,
            0,
            this.canvas.width,
            this.canvas.height
        )
        this.ctx.beginPath();
        this.setState({ isEmpty: true })
    }
    save() {
        if (this.state.isEmpty) {
            return "null"
        }
        const link = this.canvas.toDataURL();
        return link;
    }
    getPointerPos = (e) => {
        const rect = this.canvas.getBoundingClientRect();
        // use cursor pos as default
        let clientX = e.clientX;
        let clientY = e.clientY;

        // use first touch if available
        if (e.changedTouches && e.changedTouches.length > 0) {
            clientX = e.changedTouches[0].clientX;
            clientY = e.changedTouches[0].clientY;
        }
        return {
            x: clientX - rect.left,
            y: clientY - rect.top
        };
    }

    render() {

        const { classes } = this.props;
        return (
            <canvas
                ref="canvas"
                className={classes.root}
                onMouseDown={this.onmousedown}
                onMouseMove={this.handleMove}
                onMouseUp={this.onmouseup}
                onTouchStart={this.onmousedown}
                onTouchMove={this.handleMove}
                onTouchEnd={this.onmouseup}
                width={1500}
                height={750}
                style={{ width: 300, height: 150 }}
            />
        );
    }
}
DrawCanvas.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(DrawCanvas);





