import React from "react";
import { BrowserRouter as Router, Link } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import NoSsr from '@material-ui/core/NoSsr';
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
// import logo from "./Ramirent_Logo_Primary_Office.png";
import { Toolbar, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import SideDrawer from "./SideDrawer";
import LinearProgress from "@material-ui/core/LinearProgress";
import { connect } from "react-redux"
import { bindActionCreators } from "redux";
import { toggleLoader } from "../actions/common";
import RamirentLogo from "../images/Ramirent_Logo.png";


const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,

    },
    logo: {
        paddingLeft: (theme.rules.logoHeight - 20) / 4,
        height: (theme.rules.logoHeight - 20),
        paddingBottom: 0,
        paddingRight: (theme.rules.logoHeight - 20) / 4,
    },
    grow: {
        flexGrow: 1,
    },
    placeholder: {
        height: 5,
    },
    logo: {
        paddingLeft: (theme.rules.logoHeight - 20) / 4,
        height: (theme.rules.logoHeight - 20),
        paddingBottom: 0,
        paddingRight: (theme.rules.logoHeight - 20) / 4,
    },
    text:{
        position:"absolute",
        right:0,
        marginRight:20
    }
});

class NavTabs extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: 0,
        };
    }


    handleChange = (event, value) => {
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        return (
            
            <NoSsr>
                <div className={classes.root}>
                    <AppBar position="static">
                        <Toolbar>
                        {window.location.hash.includes("outokumpu")||window.location.hash.includes("metsafibre")?"":<SideDrawer isAuthenticated = {this.props.common.user.isAuthenticated} />}
                            <Link to='/'><img src={RamirentLogo} className={classes.logo} alt="Ramirent logo" /></Link>
                            <Typography className={classes.text}>{process.env.dev==="true"?"Development":"RamiForms"}</Typography>
                        </Toolbar>
                    </AppBar>
                    {this.props.common.loading ? <LinearProgress color="secondary" /> : <div className={classes.placeholder} />}
                </div>
            </NoSsr>
        );
    }
}
function mapStateToPorps(state) {
    return {
        common: state.common
    }
}
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        toggleLoader: toggleLoader,
    }, dispatch)
}
NavTabs.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default connect(mapStateToPorps, mapDispatchToProps)(withStyles(styles)(NavTabs));