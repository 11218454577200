import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { connect } from "react-redux"
import FilledRow from "./FilledRow";
import SearchFilled from './SearchFilled';
import { Typography, Box } from '@material-ui/core';
import { Link } from "react-router-dom";

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    grid: {
        width: "100%",
        margin: 0
    },
    link: {
        padding: 10,
        textAlign: "center",
    },
    
});

class FilledForms extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            updated: "ei koskaan"
        }
    }
    render() {
        const { classes } = this.props;
        if(this.props.common.user.isAuthenticated){
            return (
                <Box className={classes.root} m={5}>
                    <SearchFilled/>
                    <Grid container spacing={3} className={classes.grid}>
                        <Grid item xs={12}>
                            {this.props.filled.map((f,i) => (
                                <FilledRow  key ={"row_"+i} filled= {f}/>
                            ))}
                        </Grid>
                    </Grid>
                </Box>
            );
        }
        return (<Typography lang="en" className={classes.link} color="secondary" component={Link} to={"/login"}>Kirjaudu sisään nähdäksesi täyttämäsi lomakkeet</Typography>)
    }
}
function mapStateToPorps(state) {
    return {
        filled: state.filled,
        common:state.common
    }
}
FilledForms.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default connect(mapStateToPorps)(withStyles(styles)(FilledForms));