import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import MobileTableCard from "./MobileTableCard";
import MobileTableRow from "./MobileTableRow";
import { withSnackbar } from 'notistack';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import withMobileDialog from '@material-ui/core/withMobileDialog';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const styles = theme => ({
    cards: {
        display: "flex",
        flexWrap: "wrap",
        // [theme.breakpoints.up('sm')]: {
        //     flexDirection: "column"
        // },
    },
    button: {
        margin: theme.spacing(1),
        fontFamily: "Boing-SemiBold",
        textTransform: "none",

    },
    content: {
        display: "flex",
        flexDirection: "column"
    }
});

class RadioGroupChooser extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return <FormControl component="fieldset">
            <FormLabel component="legend" className="form-label">{this.props.label}</FormLabel>
            <RadioGroup row aria-label="position" name="position" defaultValue="top" value={this.props.value} onChange={(event) => {
                    this.props.onChange(event);
                }}>
                {
                    this.props.values.map((radioFiledDefinition, i) => {
                        return <FormControlLabel
                            key={radioFiledDefinition.value + i}
                            value={radioFiledDefinition.value}
                            control={<Radio color="primary" />}
                            label={radioFiledDefinition.label}
                            labelPlacement="top"
                        />
                    })
                }
            </RadioGroup>
        </FormControl>
    }
}

const tablePropsHaveRadioGroupValueDefinitions = (tableProps, label) => {
    return tableProps &&
        tableProps.hasOwnProperty(label) &&
        tableProps[label].values && 
        tableProps[label].values.length > 0;
};

const isChecked = (value) => {
    return value === true;
};

class MobileTable extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false,
            values: [],
            edit: false,
            width: 0
        };
        this.props.fields.map((f, i) => {
            if (i === 0) {
                this[i] = React.createRef();
            }
            this.state[i] = ""
        })
    }
    componentDidMount = () => {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount = () => {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth });
    }
    handleClickOpen = () => {
        this.setState({ open: true, edit: false });
    };

    handleClose = () => {
        this.clear();
        this.setState({ open: false });
    };
    handleChange = name => event => {
        let target = "";
        event.target.type === "checkbox" ? target = "checked" : target = "value"
        this.setState({
            [name]: event.target[target],
        });
    };
    handleAdd = (event) => {
        if (event.key === "Enter" || event.type === "click") {
            event.preventDefault();
            event.stopPropagation();
            let row = [];
            this.props.fields.map((f, i) => {
                row.push(this.state[i]);
            })
            if (this.state.edit || this.state.edit === 0) {
                let newValues = this.state.values;
                newValues[this.state.edit] = row;
                this.setState({ values: newValues }, () => this.clear())
                this.handleClose();
            } else {
                this.setState({ values: [...this.state.values, row] }, this.clear())
                this.props.enqueueSnackbar(`Lisätty`, { variant: "success", autoHideDuration: 1000 });
            }
        } else if (event.key === "Escape") {
            this.handleClose();
        }
    }
    handleEdit = (index) => {
        let newState = { open: true, values: this.state.values, edit: index }
        this.props.fields.map((f, i) => {
            newState[i] = this.state.values[index][i]
        })
        this.setState(newState);
        // this.clear();
    }
    handleCopy = (index) => {
        let newValues = this.state.values;
        newValues.push(newValues[index]);
        this.setState({ values: newValues }, this.props.handleTableChange(this.state.values));
    }
    handleDelete = (index) => {
        let newValues = this.state.values;
        newValues.splice(index, 1);
        this.setState({ values: newValues }, this.props.handleTableChange(this.state.values));
    }
    clear = () => {
        let newState = { open: true, values: this.state.values, edit: this.state.edit }
        this.props.fields.map((f, i) => {
            newState[i] = ""
        })
        this.setState(newState);
        this.props.handleTableChange(this.state.values);
    }
    render() {
        const { fields, classes, fullScreen, tableProps } = this.props;
        return (
            <div>
                <Button variant="contained" color="primary" onClick={this.handleClickOpen} className={classes.button}>
                    Lisää rivi
                </Button>
                {this.state.width < 960 ?
                    <div className={classes.cards}>
                        {this.state.values.map((v, i) => (
                            <MobileTableCard
                                key={"card" + i}
                                value={v}
                                fields={fields}
                                index={i}
                                handleEdit={this.handleEdit}
                                handleCopy={this.handleCopy}
                                handleDelete={this.handleDelete}
                            />
                        ))}
                    </div>
                    :
                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow>
                                <TableCell key="empty" className={classes.cell}></TableCell>
                                {fields.map(f => {
                                    return (<TableCell key={f.label}>{f.label}</TableCell>)
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.values.map((v, i) => (
                                <MobileTableRow
                                    key={"card" + i}
                                    value={v}
                                    fields={fields}
                                    index={i}
                                    handleEdit={this.handleEdit}
                                    handleCopy={this.handleCopy}
                                    handleDelete={this.handleDelete}
                                />))}
                        </TableBody>
                    </Table>
                }

                <Dialog
                    open={this.state.open}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    fullScreen={fullScreen}
                    maxWidth="sm"
                    onKeyDown={this.handleAdd}
                    scroll="body"
                >
                    <DialogTitle id="form-dialog-title">Lisää rivi</DialogTitle>
                    <DialogContent className={classes.content}>
                        {fields.map((f, index) => {
                            switch (f.type) {
                                case "checkbox":
                                    return (
                                        <FormControlLabel key={f.label} control={
                                            <Checkbox
                                                checked={isChecked(this.state[index])}
                                                onChange={this.handleChange(index)}
                                                value={`${f.label}`}
                                            />}
                                            label={f.label}
                                        />)
                                case "dropdown":
                                    return (
                                        <Dropdown
                                            name={f.label}
                                            // label=""
                                            value={this.state[index]}
                                            options={f.props}
                                            onDropdownChange={this.handleChange(index)}
                                        />
                                    )
                                case "decimalNumber":
                                    return (
                                        <TextField
                                            key={f.label}
                                            type="number"
                                            onChange={this.handleChange(index)}
                                            id={f.label}
                                            inputProps={{ step: 0.01 }}
                                            value={this.state[index]}
                                            margin="normal"
                                            inputRef={el => this[index] = el}
                                        />)
                                case "radio":
                                    if (tablePropsHaveRadioGroupValueDefinitions(tableProps, f.label)) {
                                        return <RadioGroupChooser
                                            key={f.label + index}
                                            values={tableProps[f.label].values}
                                            onChange={this.handleChange(index)}
                                            label={f.label}
                                            value={this.state[index]}
                                        />
                                    };
                                default:
                                    return (
                                        <TextField
                                            key={f.label}
                                            type={f.type}
                                            label={f.label}
                                            onChange={this.handleChange(index)}
                                            id={f.label}
                                            value={this.state[index]}
                                            margin="normal"
                                            inputRef={el => this[index] = el}
                                        />)
                            }
                        })}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.handleAdd} color="secondary">
                            {this.state.edit ? "Päivitä" : "Lisää"}
                        </Button>
                        <Button onClick={this.handleClose} color="primary">
                            Sulje
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }
}
MobileTable.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(withSnackbar(withMobileDialog()(MobileTable)))