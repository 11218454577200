import React from "react";
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Input from "@material-ui/core/Input";
import AddIcon from "@material-ui/icons/Add";
import InputLabel from "@material-ui/core/InputLabel";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import DeleteIcon from "@material-ui/icons/Delete";
import { Typography } from "@material-ui/core";
import Doc from "../images/pdf.svg";
const styles = theme => ({
    root: {
        display: "flex",
        // flexDirection: "column"
        flexWrap: "wrap",
        paddingTop: "2em"
    },
    label: {
        width: "100%",
    },
    doc: {
        width: "50%",
        // height: "auto"
    },
    imgDiv: {
        width: "10em",
        margin: "1em",
    },
    preview: {
        width: "100%"
    },
    input: {
        opacity: 0,
    },
    button: {
        padding: 10,
        '&:hover': {

            backgroundColor: "rgba(222, 218, 213, 0.51);",
            borderRadius: 30,
        }
    },
    addText: {
        padding: 15
    },
    addNew: {
        width: "100%",
        display: "flex",
        flexWrap: "wrap"
    },
    deleteIcon: {
        position: "absolute",
        '&:before': {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            borderTop: "50px solid rgba(255, 220, 0, 1)",
            borderRight: "50px solid transparent",
            width: 0,
        }
    },
    deleteButton: {
        padding: 3
    },
    nowrap: {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
    }


});

const reader = new FileReader();
class FileElement extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            imgs: [],
            dataUrls: [],
            types: []
        }

    }
    handleChange = name => event => {
        let imgs = this.state.imgs;
        let dataUrls = this.state.dataUrls;
        let types = this.state.types;
        if (name.includes("delete")) {
            let id = name.split("_").pop();
            // console.log(id)
            imgs.splice(id, 1);
            dataUrls.splice(id, 1);
            types.splice(id, 1),
                this.setState({ dataUrls: dataUrls, imgs: imgs, types: types });
            this.props.handleChange(dataUrls)
        } else {
            if (event.target.files[0] === undefined) {
                return
            } else {
                let count = 0;
                const files = event.target.files;
                let objectUrl = URL.createObjectURL(files[count])
                imgs.push(objectUrl)
                types.push(files[count].name);
                reader.readAsDataURL(files[count]);

                reader.onload = () => {
                    dataUrls.push(reader.result);
                    this.setState({ dataUrls: dataUrls, imgs: imgs, types: types });
                    count++
                    if (count < files.length) {
                        objectUrl = URL.createObjectURL(files[count])
                        imgs.push(objectUrl)
                        types.push(files[count].name);
                        reader.readAsDataURL(files[count]);
                    }else{
                        this.props.handleChange(dataUrls)
                    }
                }
            }
        }


    }
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Typography variant="h6" className={classes.label}>{this.props.label}</Typography>
                {this.state.imgs.map((img, i) => {
                    return <div className={classes.imgDiv} key={"img_" + i}>
                        <div className={classes.deleteIcon}>
                            <IconButton className={classes.deleteButton} onClick={this.handleChange("delete_" + i)}>
                                <DeleteIcon />
                            </IconButton>
                        </div>
                        <img
                            className={this.state.types[i].split(".").pop() === "jpg" || this.state.types[i].split(".").pop() === "png" ? classes.preview : classes.doc}
                            src={this.state.types[i].split(".").pop() === "jpg" || this.state.types[i].split(".").pop() === "png" ? img : Doc}
                        />
                        <Typography className={classes.nowrap} variant="body2" color="textSecondary">{this.state.types[i]}</Typography>
                    </div>
                })}

                <label className={classes.addNew}>
                    {/* <Typography>{this.props.label}</Typography> */}
                    <AddIcon className={classes.button} />
                    <Typography className={classes.addText}>Lisää tiedosto</Typography>
                    <input key="file"
                        className={classes.input}
                        type="file"
                        name="test"
                        multiple
                        accept="image/png, image/jpeg, application/pdf, image/jpg"
                        onChange={this.handleChange("file")}
                    />
                </label>
            </div>
        );
    }
}
FileElement.propTypes = {
    classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(FileElement);