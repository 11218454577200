import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Typography } from '@material-ui/core';


const styles = theme => ({

    button: {
        margin: theme.spacing(1),
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
    fab: {
        position: 'fixed',
        bottom: 10,
        right: 10,
    },
    changers: {
        marginTop: 4,
    },
    label:{
        hyphens:"auto"
    }
});


class Dropdown extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            editFilledRoles: "",
        };
    }
    renderMenuItems = () => {
        let items = [];
        if(this.props.addNone){
            items.push(<option key={"none"} value=""></option>);
        };
        this.props.options.map(o => {
            items.push (<option key={o.value} value={o.value}>{o.label}</option>)
        })
       
        return items;
    }
    render() {
        const { classes } = this.props;
        return (
            <FormControl required={this.props.required} key = {this.props.name} fullWidth className={classes.changers}>
                <Typography htmlFor={this.props.name+"-required"} lang="en" className={classes.label} variant="subtitle1" color="textSecondary">{this.props.label}{this.props.required?"*":""}</Typography>
                <Select
                    native
                    value={this.props.value}
                    inputProps={{
                        id:this.props.name+"-required"
                    }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                        }
                    }}
                    onChange={this.props.onDropdownChange(`${this.props.name}`)}>
                    {this.renderMenuItems()}
                </Select>
            </FormControl>
        )
    }
}
Dropdown.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Dropdown);