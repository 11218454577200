import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux'
import dataReducer from './data';
import linkReducer from './link';
import commonReducer from './common';
import filledReducer from './filled';
import filledFormsReducer from "./filledForms";


const appReducer = combineReducers({
    data: dataReducer,
    link: linkReducer,
    common: commonReducer,
    filled: filledReducer,
    filledForms: filledFormsReducer
})
const rootReducer = (state, action) => {
    // console.log(action.type);
    if (action.type === "SIGNOUT_REQUEST") {
        // for all keys defined in your persistConfig(s)
        storage.removeItem('persist:root')
        storage.removeItem('persist:common')
        storage.removeItem('persist:data')
        storage.removeItem('persist:link')
        storage.removeItem('persist:filled')
        storage.removeItem('persist:filledForms')
        state = undefined;
    }

    return appReducer(state, action);
};
export default rootReducer