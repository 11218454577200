import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import B1 from "../images/b1.jpg";
import B2 from "../images/b2.jpg";
import FilledForms from '../images/filled-forms.jpg';
import ButtonBase from "@material-ui/core/ButtonBase";
import theme from "./theme";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import Favorites from "./Favorites";
import Rf3Link from './Rf3Link';

const styles = theme => {
  return {
    base: {
      width: '100%',
      // marginTop: 10,
  
    },
    header: {
      textAlign: "center",
      fontFamily: "Boing-Bold",
      color: theme.palette.text.primary
    },
    line: {
      width: 65,
      height: 3,
      backgroundColor: "rgb(255, 220, 0)",
      marginTop: 12,
      position: "absolute",
      bottom: 18,
      left: 26,
    },
    content: {
      background: "linear-gradient(rgba(0, 0, 0, 0.1) 40%, rgba(0, 0, 0, 0.3) 60%, rgba(0, 0, 0, 0.8))",
      height: "100%",
      width: "100%",
      borderRadius: 10
    },
    headerdiv: {
      padding: 30,
    },
    button: {
      paddingRight: 100,
      minWidth: 330
    },
    buttons: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "center",
      alignContent: "center",
  
      padding: 10,
  
  
    },
    imageSrc: {
      height: 100,
      padding: 20,
    },
  
    imageTitle: {
      // color: theme.palette.text.primary,
      fontFamily: "Boing-Regular",
      padding: 15,
      position: "absolute",
      left: 10,
      bottom: 10
    }
  };
};

class Home extends React.Component {

  shouldDisplayTtl() {
    return this.props.common.user.isAuthenticated;
  }

  isAuthenticated() {
    return this.props.common.user.isAuthenticated;
  }

  buildClickableImagesList() {
    const images = [
      {
        url: B1,
        title: 'Lomakkeet',
        color: theme.palette.primary.main,
        textColor: theme.palette.text.white,
        to: "/forms"
      }
    ];
    if (!this.isAuthenticated()) {
      images.push({
        url: B2,
        title: 'Kirjaudu',
        color: theme.palette.secondary.main,
        textColor: theme.palette.text.white,
        to: '/login'
      })
    }
    if (this.isAuthenticated()) {
      images.push({
          url: FilledForms,
          title: 'Täyttämäni lomakkeet',
          color: theme.palette.primary.main,
          textColor: theme.palette.text.white,
          to: "/filled"
        }
      );
    }
    return images;
  }
  
  
  
  render() {
    const { classes } = this.props;
  
    return (
      <div className={classes.base}>
        <div className={classes.headerdiv}>
          <Typography className={classes.header} variant="h3">RamiForms</Typography>
        </div>
        <div className={classes.buttons}>
          {this.buildClickableImagesList().map(image => {
            return image!==""?<ButtonBase
              className={classes.button}
              focusRipple
              key={image.title}
              className={classes.image}
              focusVisibleClassName={classes.focusVisible}
              component={Link} to={image.to}
              style={{
                backgroundImage: `url(${image.url})`,
                width: window.innerWidth < 360 ? "90%" : 360,
                height: 200,
                margin: 10,
                borderRadius: 10
              }}
            >
              <div className={classes.content}>
                <span className={classes.imageButton}>
                  <Typography
                    component="span"
                    variant="h4"
                    // color={image.textColor||""}
                    style={{ color: image.textColor }}
                    className={classes.imageTitle}
                  >
                    {image.title}
                    {/* <span className={classes.imageMarked} /> */}
                  </Typography>
                  <div className={classes.line} />
                </span>
              </div>
              {/* {window.innerWidth > 320 ?
              <img
                className={classes.imageSrc}
                src={image.url}
              // style={{
              //   backgroundColor: image.color,
              //   backgroundImage: `url(${image.url})`,
              // }}
              /> : ""} */}
              {/* <span className={classes.imageBackdrop} /> */}

            </ButtonBase>:""
          }

          )}
          { this.isAuthenticated() ? <Rf3Link /> : null }
          { this.isAuthenticated() ? <Favorites /> : null }
        </div>
        
      </div>
    );
  }

}
function mapStateToPorps(state) {
  return {
    common: state.common,
  }
}
Home.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default connect(mapStateToPorps)(withStyles(styles)(Home));